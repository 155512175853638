import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { AiFillEdit, AiFillLock, AiFillUnlock } from "react-icons/ai";
import { FaCloudUploadAlt, FaSave, FaShare } from "react-icons/fa";
import Submit from "../../../../components/Form/Submit";
import Grid from "../../../../components/Grid";
import { FlexContainer } from "../../../../components/StyledComponents";
import {
  schema,
  initialValues,
} from "../../../../constants/form/slideshow/slideshow-creator";
import TextArea from "./components/TextArea";
import { RiCloseCircleFill } from "react-icons/ri";
import {
  SlideShowUploaderDescriptionWrapper,
  SlideShowUploaderTitle,
  SlideShowUploadWrapper,
  SlideShowImagePreview,
  PageNumberWrapper,
  PageNumber,
} from "./style";
import InputDescription from "./components/Field/index";
import { BiLink } from "react-icons/bi";
import { BsFillTrash2Fill } from "react-icons/bs";
import { FiUpload } from "react-icons/fi";
import { HiUserAdd } from "react-icons/hi";
import { toast } from "react-toastify";
import { showModal } from "../../../../store/actions/modal";
import { connect, useDispatch } from "react-redux";
import SideBar from "./components/SideBar";
import Loader from "../../../../components/Loader";
import { Page, Document } from "react-pdf";
import { useNavigate, useParams } from "react-router-dom";
import { StateDto } from "../../../../types/states.dto";
import Button from "../../../../components/Button";
import PublishSlideShowModal from "./components/PublishSlideShowModal";

import AddUsersModal from "./components/AddUsersModal";
import { UsersDto } from "../../../../types/users.dto";
import ShareModal from "./components/ShareModal";
import PrivateModal from "./components/PrivateModal";
import ModalConfirmDelete from "../../../../components/ModalConfirmDelete";
import { CurrentDto } from "../../../../types/current.dto";
import { setFormData } from "../../../../helpers/formData";
import { SlideShowDto } from "../../../../types/slideshow.dto";
import Input from "../../../../components/Form/Input";
import {
  GET_SLIDESHOW_SUCCESS,
  SET_GET_SLIDESHOW,
} from "../../../../store/types/slideshow";
import {
  deleteSlideShow,
  getSlideShow,
  resetDeleteSlideShow,
  resetGetSlideShow,
  resetNewSlideShow,
  newSlideShow,
  updateSlideShow,
} from "../../../../store/actions/slideshow";
import axios from "axios";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "./style.css";
import DropzoneField from "../../../../components/Form/Dropzone";
import { getAllUnits, resetGetAllUnits } from "../../../../store/actions/unit";
import {
  getAllCohorts,
  resetGetAllCohortsData,
} from "../../../../store/actions/cohort";
import { CohortDto } from "../../../../types/cohort.dto";
import { UnitDto } from "../../../../types/unit.dto";
const Component = ({
  slideshow,
  users,
  current,
  user,
  newSlideShowDataStates,
  newSlideShowData,
  updateSlideShowStates,
  getSlideShowStates,
  deleteSlideShowStates,
  cohorts,
  units,
}: {
  slideshow: SlideShowDto;
  users: UsersDto[];
  current: CurrentDto;
  user: UsersDto;
  newSlideShowDataStates: StateDto;
  newSlideShowData: SlideShowDto;
  updateSlideShowStates: StateDto;
  getSlideShowStates: StateDto;
  deleteSlideShowStates: StateDto;
  cohorts: CohortDto[];
  units: UnitDto[];
}) => {
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [currentImage, setCurrentImage] = useState<{
    file: any;
    preview: any;
  }>();
  const [currentPdf, setCurrentPdf] = useState<{
    file: any;
    preview: any;
  }>();
  const [pages, setPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [slideshowDataChanged, setSlideShowDataChanged] = useState<{
    name: string;
    description: string;
  }>({ name: "", description: "" });
  const [imageApi, setImageApi] = useState<string>();
  const [pdfResponse, setPdfResponse] = useState();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  function onDocumentLoadSuccess({ numPages }) {
    setPages(numPages);
    setPageNumber(1);
  }
  const onSubmit = (values, actions) => {
    if (!slideshow) {
      if (!currentPdf || !currentImage) {
        toast.error("Se necesita presentacion y imagen");
        return null;
      }
      dispatch(
        newSlideShow(
          setFormData(
            {
              name: values.name,
              description: values.description,
              ...(!!currentImage && { picture: currentImage.file }),
              slides: currentPdf?.file,
              academy: current.id,
              type: "SLIDESHOW",
              published: true,
              allCohorts: true,
              available: true,
            },
            ["picture", "slides"]
          )
        )
      );
    } else {
      if (!slideshow.picture && !currentImage) {
        toast.error("Se necesita imagen");
        return null;
      }
      dispatch(
        updateSlideShow({
          _id: slideshow._id,
          body: setFormData(
            {
              name: values.name,
              description: values.description,
              ...(!!currentImage && { picture: currentImage.file }),
              published: true,
              allCohorts: values.allCohorts,
              available: values.available,
              __v: slideshow.__v,
            },
            ["picture"]
          ),
        })
      );
    }
  };

  const onSelectImage = (files) => {
    if (files.length <= 0) {
      setCurrentImage(undefined);

      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setCurrentImage({
      file: files[0],
      preview: URL.createObjectURL(files[0]),
    });
  };

  const onSelectPdf = (files) => {
    if (files.length <= 0) {
      setCurrentPdf(undefined);

      return;
    }

    // I've kept this example simple by using the first image instead of multiple

    setCurrentPdf({
      file: files[0],
      preview: URL.createObjectURL(files[0]),
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowLeft" && pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    } else if (event.key === "ArrowRight" && !!pages && pageNumber < pages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const menu = [
    {
      slideshowRole: ["OWNER"],
      Icon: FiUpload,
      toolTipName: !!slideshow?.published
        ? "Editar publicacion"
        : "Publicar Contenido",
      onClick: () => {
        dispatch(showModal("publish-slideshow-modal"));
      },
      IconActive: AiFillEdit,
      activeFunction: () => !!slideshow?.published,
      onClickActive: () => {
        dispatch(showModal("publish-slideshow-modal"));
      },
    },
    {
      Icon: AiFillLock,
      slideshowRole: ["OWNER"],
      IconActive: AiFillUnlock,
      toolTipName: "Cambiar visibilidad",
      onAvailable: () => !!slideshow && !!slideshow?.published,
      activeFunction: () => !!slideshow?.published,
      onClick: () => {
        dispatch(showModal("private-slideshow"));
      },
      onClickActive: () => {
        dispatch(showModal("private-slideshow"));
      },
    },
    {
      Icon: HiUserAdd,
      slideshowRole: ["OWNER", "COOWNER"],
      toolTipName: "Añadir Usuarios",
      onClick: () => {
        dispatch(showModal("add-user-slideshow"));
      },
      onAvailable: () => !!slideshow && !!slideshow?.published,
    },
    {
      Icon: FaSave,
      onClick: () => {},
      toolTipName: "Guardar",
      slideshowRole: ["OWNER", "COOWNER"],
      activeFunction: () =>
        (!slideshow &&
          !!currentPdf &&
          slideshowDataChanged.name?.length > 5 &&
          slideshowDataChanged.description?.length > 0 &&
          !!currentImage) ||
        (!!slideshow &&
          slideshowDataChanged.name?.length > 5 &&
          slideshowDataChanged.description?.length > 0 &&
          (!!imageApi || !!currentImage) &&
          (slideshowDataChanged.description !== slideshow.description ||
            slideshowDataChanged.name !== slideshow.name)),
      onAvailable: () =>
        (!slideshow &&
          !!currentPdf &&
          slideshowDataChanged.name?.length > 5 &&
          slideshowDataChanged.description?.length > 0 &&
          !!currentImage) ||
        (!!slideshow &&
          slideshowDataChanged.name?.length > 5 &&
          slideshowDataChanged.description?.length > 0 &&
          (!!imageApi || !!currentImage) &&
          (slideshowDataChanged.description !== slideshow.description ||
            slideshowDataChanged.name !== slideshow.name)),
      onClickActive: () => {
        if (!slideshow) {
          if (!currentPdf || !currentImage) {
            toast.error("Se necesita presentacion y imagen");
            return null;
          }
          dispatch(
            newSlideShow(
              setFormData(
                {
                  name: slideshowDataChanged.name,
                  description: slideshowDataChanged.description,
                  slides: currentPdf?.file,
                  ...(!!currentImage && { picture: currentImage?.file }),

                  type: "SLIDESHOW",

                  academy: current.id,
                },
                ["picture", "slides"]
              )
            )
          );
        } else {
          if (!slideshow.picture && !currentImage) {
            toast.error("Se necesita imagen");
            return null;
          }
          dispatch(
            updateSlideShow({
              _id: slideshow._id,
              body: setFormData(
                {
                  name: slideshowDataChanged.name,
                  description: slideshowDataChanged.description,
                  ...(!!currentImage && { picture: currentImage?.file }),
                  __v: slideshow.__v,
                },
                ["picture"]
              ),
            })
          );
        }
      },
    },
    {
      Icon: BiLink,
      slideshowRole: ["OWNER", "COOWNER", "READER"],
      toolTipName: "Copiar Link",
      onAvailable: () =>
        !!slideshow && !!slideshow.available && !!slideshow.published,
      onClick: () => {
        navigator.clipboard.writeText(
          `https://academia.batan.coop/dashboard/videos/${slideshow?._id}`
        );
        toast.success(`Link copiado`);
      },
    },
    {
      Icon: FaShare,
      toolTipName: "Compartir",
      slideshowRole: ["OWNER", "COOWNER", "READER"],
      onAvailable: () =>
        !!slideshow && !!slideshow.available && !!slideshow.published,
      onClick: () => {
        dispatch(showModal("share-user-slideshow"));
      },
    },
    {
      Icon: BsFillTrash2Fill,
      slideshowRole: ["OWNER"],
      toolTipName: "Eliminar SlideShow",
      onAvailable: () => !!slideshow,
      onClick: () => {
        dispatch(showModal("confirm-delete-item-secuencial-modal"));
      },
    },
  ];

  useEffect(() => {
    !cohorts &&
      fetchOn &&
      dispatch(
        getAllCohorts({ filterBy: { academy: current.id, active: true } })
      );
  }, [cohorts, fetchOn]);

  useEffect(() => {
    if (cohorts && fetchOn) {
      dispatch(
        getAllUnits({
          filterBy: { cohort: { $in: cohorts.map((cohort) => cohort._id) } },
        })
      );
    }
  }, [cohorts]);
  useEffect(() => {
    if (!cohorts) {
      setFetchOn(true);
    }
  }, [cohorts]);

  useEffect(() => {
    if (!!params.id) {
      dispatch(getSlideShow({ _id: params.id }));
    }
    if (!params.id && !!slideshow) {
      dispatch({ type: SET_GET_SLIDESHOW, response: null });
      setPdfResponse(undefined);
    }
  }, [params.id]);

  useEffect(() => {
    if (!!slideshow) {
      setSlideShowDataChanged({
        name: slideshow.name,
        description: slideshow.description,
      });
      setImageApi(slideshow.picture);
      axios({
        method: "GET",
        url: slideshow.slides,
        responseType: "arraybuffer",
      })
        .then((response) => {
          setPdfResponse(response.data);
        })
        .catch((err) => setPdfResponse(undefined));
    } else {
      setPdfResponse(undefined);
      setSlideShowDataChanged({
        name: "",
        description: "",
      });
      setImageApi(undefined);
    }
  }, [slideshow]);

  useEffect(() => {
    if (newSlideShowDataStates.success) {
      toast.success("Se creo el slideshow exitosamente");
      if (!!newSlideShowData) {
        navigate(newSlideShowData._id);
      }
      setTimeout(() => {
        dispatch(resetNewSlideShow());
      }, 1000);
    }
    if (newSlideShowDataStates.error) {
      toast.error("No se pudo crear el cotenido");
      setTimeout(() => {
        dispatch(resetNewSlideShow());
      }, 1000);
    }
  }, [newSlideShowDataStates]);

  useEffect(() => {
    if (updateSlideShowStates.success) {
      dispatch(getSlideShow({ _id: slideshow._id }));
      toast.success(`👌🏼 SlideShow guardado`);
      dispatch(resetGetSlideShow());
    }
    if (updateSlideShowStates.error) {
      dispatch(getSlideShow({ _id: slideshow._id }));
      toast.error(
        `${updateSlideShowStates.error || "No se pudo actualizar slideshow"}`
      );
      dispatch(resetGetSlideShow());
    }
  }, [updateSlideShowStates]);

  useEffect(() => {
    if (getSlideShowStates.success) {
      dispatch(resetGetSlideShow());
    }
    if (getSlideShowStates.error) {
      dispatch(resetGetSlideShow());
    }
  }, [getSlideShowStates]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCohortsData());
      dispatch(resetGetAllUnits());
    };
  }, []);

  if ((!!params.id && !slideshow) || getSlideShowStates.loading)
    return <Loader color="Primary"></Loader>;
  return (
    <>
      <Grid.Container>
        <Formik
          initialValues={initialValues({
            name: slideshowDataChanged.name,
            description: slideshowDataChanged.description,
          })}
          onSubmit={(values, actions) => {
            onSubmit(values, actions);
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={schema}
          validateOnMount={false}
          enableReinitialize
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <form
                className="theme-form"
                onSubmit={(event) => {
                  setFormSubmmited(true);
                  handleSubmit(event);
                }}
                id="slideshow-player-create"
              >
                <FlexContainer gap="10px">
                  <div style={{ width: "calc(100% - 50px)" }}>
                    <FlexContainer direction="column" gap="10px">
                      {!!currentPdf ? (
                        <SlideShowUploadWrapper
                          onClick={(ev) => {
                            ev.stopPropagation();
                          }}
                          height={"500px"}
                        >
                          <RiCloseCircleFill
                            onClick={(ev) => {
                              ev.stopPropagation();
                              setCurrentPdf(undefined);
                            }}
                            size={25}
                            fill={"#E91E63"}
                            color={"#fff"}
                            style={{
                              position: "absolute",
                              top: "10px",
                              right: "10px",
                            }}
                          ></RiCloseCircleFill>
                          <SlideShowUploaderTitle className="title">
                            {currentPdf.file.name}
                          </SlideShowUploaderTitle>
                        </SlideShowUploadWrapper>
                      ) : !!slideshow?.slides && pdfResponse ? (
                        <SlideShowUploadWrapper
                          style={{ position: "relative", overflow: "auto" }}
                          height={"500px"}
                          onKeyDown={handleKeyDown}
                          tabIndex={0}
                        >
                          <PageNumberWrapper>
                            <PageNumber>{`${pageNumber}/${pages}`}</PageNumber>
                          </PageNumberWrapper>
                          <Document
                            loading={<Loader color="Violet"></Loader>}
                            noData={<h2>No se encontro</h2>}
                            onLoadSuccess={onDocumentLoadSuccess}
                            file={pdfResponse}
                          >
                            <Page
                              loading={<Loader color="Violet"></Loader>}
                              scale={1}
                              key={`page_${pageNumber}`}
                              pageNumber={pageNumber}
                            />
                          </Document>
                        </SlideShowUploadWrapper>
                      ) : (
                        <DropzoneField
                          onChange={(files) => {
                            toast.info(
                              "Los unicos formatos que se pueden previsualizar antes de crearse son ppt, pptx, pdf"
                            );
                            onSelectPdf(files);
                          }}
                          onBlur={handleBlur}
                          name="slideshowFile"
                          error={errors["slideshowFile"]}
                          touched={touched["slideshowFile"]}
                          placeholder={
                            "Selecciona o arrastra el archivo que quieras subir"
                          }
                          options={{ size: "big", openOnClick: true }}
                          validation={{
                            maxFileSize: 1024,
                            maxFiles: 1,
                            accept:
                              "application/vnd.ms-powerpoint,.ppt,.pptx,application/pdf,.pdf",
                          }}
                        />
                      )}
                      <SlideShowUploaderDescriptionWrapper>
                        <FlexContainer
                          justify="space-between"
                          wrap="wrap"
                          gap="10px"
                        >
                          <FlexContainer direction="column" gap="12px">
                            <FlexContainer
                              direction="column"
                              gap="12px"
                              style={{ width: "360px" }}
                            >
                              <InputDescription
                                containerStyles={{
                                  width: "100%",
                                  maxWidth: "340px",
                                  borderColor: "#697482",
                                }}
                                error={errors["name"]}
                                name="name"
                                touched={touched["name"]}
                                value={values["name"]}
                                type="text"
                                placeholder="Nombre del SlideShow"
                                onChange={(ev) => {
                                  setSlideShowDataChanged((state) => {
                                    return {
                                      ...state,
                                      name: ev.target.value,
                                    };
                                  });
                                  handleChange(ev);
                                }}
                                onBlur={handleBlur}
                                options={{
                                  marginBottom: 10,
                                }}
                              />
                              <TextArea
                                containerStyles={{
                                  width: "100%",
                                  maxWidth: "340px",
                                }}
                                name="description"
                                error={errors["description"]}
                                touched={touched["description"]}
                                value={values["description"]}
                                onChange={(ev) => {
                                  setSlideShowDataChanged((state) => {
                                    return {
                                      ...state,
                                      description: ev.target.value,
                                    };
                                  });
                                  handleChange(ev);
                                }}
                                placeholder="Escriba su descripcion"
                                onBlur={handleBlur}
                                options={{
                                  skin: "base",
                                  marginBottom: 10,
                                }}
                              />
                              <FlexContainer
                                gap="10px"
                                align="center"
                                wrap="wrap"
                              >
                                {!slideshow && (
                                  <>
                                    <Submit
                                      isSubmmiting={
                                        updateSlideShowStates.loading ||
                                        newSlideShowDataStates.loading
                                      }
                                      form="slideshow-player-create"
                                      color="Primary"
                                      options={{
                                        type: "filled",
                                        skin: "primary",
                                        size: "lg",
                                      }}
                                    >
                                      Publicar Rapido
                                    </Submit>

                                    <Button
                                      onClick={() => {
                                        dispatch(
                                          showModal("publish-slideshow-modal")
                                        );
                                      }}
                                      type="button"
                                      options={{
                                        type: "filled",
                                        skin: "primary",
                                        size: "lg",
                                      }}
                                      style={{ marginLeft: "10px" }}
                                    >
                                      Publicar
                                    </Button>
                                  </>
                                )}
                              </FlexContainer>
                            </FlexContainer>
                          </FlexContainer>
                          {!currentImage && !imageApi ? (
                            <DropzoneField
                              name="videoImg"
                              onChange={(files) => {
                                onSelectImage(files);
                              }}
                              onBlur={handleBlur}
                              error={
                                !currentImage &&
                                !imageApi &&
                                "Se necesita subir imagen"
                              }
                              touched={touched["videoImg"]}
                              placeholder={
                                "Selecciona el archivo que quieras subir"
                              }
                              options={{ size: "small", openOnClick: true }}
                              validation={{
                                maxFileSize: 1024,
                                maxFiles: 1,
                                accept: "image/*",
                              }}
                            />
                          ) : (
                            <SlideShowImagePreview
                              height={"200px"}
                              style={{
                                width: "340px",
                                ...(!!currentImage && {
                                  backgroundImage: `url(${currentImage?.preview})`,
                                }),
                                ...(!!imageApi && {
                                  backgroundImage: `url(${imageApi})`,
                                }),
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center center",
                              }}
                            >
                              <RiCloseCircleFill
                                onClick={() => {
                                  if (!!imageApi) {
                                    setImageApi(undefined);
                                  } else {
                                    setCurrentImage(undefined);
                                  }
                                }}
                                size={25}
                                fill={"#E91E63"}
                                color={"#fff"}
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  right: "10px",
                                }}
                              ></RiCloseCircleFill>
                            </SlideShowImagePreview>
                          )}
                        </FlexContainer>
                      </SlideShowUploaderDescriptionWrapper>
                    </FlexContainer>
                  </div>
                  <SideBar slideshow={slideshow} menu={menu}></SideBar>
                </FlexContainer>
              </form>
            );
          }}
        </Formik>
      </Grid.Container>

      <PublishSlideShowModal
        slideshowDataChanged={slideshowDataChanged}
        imageApi={imageApi}
        currentPdf={currentPdf}
        currentImage={currentImage}
        slideshow={slideshow}
      ></PublishSlideShowModal>

      {!!slideshow && <AddUsersModal slideshow={slideshow}></AddUsersModal>}
      {!!slideshow && <ShareModal slideshow={slideshow}></ShareModal>}
      {!!slideshow && (
        <PrivateModal
          slideshow={{
            _id: slideshow._id,
            published: slideshow.published,
            name: slideshowDataChanged.name,
            description: slideshowDataChanged.description,
            __v: slideshow.__v,
          }}
        ></PrivateModal>
      )}

      {!!slideshow && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Eliminar",
            style: {
              style: { width: "170px", height: "40px" },
              color: "Danger",
              options: {
                type: "filled",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
              },
            },
          }}
          sucessAction={() => navigate("/dashboard/contents/slideshows")}
          states={deleteSlideShowStates}
          description={`El SlideShowse eliminara para siempre del sistema.`}
          title={
            <span
              style={{
                color: "#697482",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              {`¿Seguro que quiere eliminar a ${slideshow.name}?`}
            </span>
          }
          elementActions={deleteSlideShow({ _id: slideshow._id })}
          resetAction={resetDeleteSlideShow}
          resetState={() => {}}
        ></ModalConfirmDelete>
      )}
    </>
  );
};

const states = ({
  usersStore,
  currentStore,
  userStore,
  slideshowStore,
  cohortStore,
  unitStore,
}) => {
  const { states: slideshowGetStates } = slideshowStore.slideshow;
  const { states: slideshowUpdateStates } = slideshowStore.update;
  const { states: slideshowDeleteStates } = slideshowStore.delete;
  const { states: newSlideShowDataStates, data: newSlideShowData } =
    slideshowStore.new;
  const { data: users } = usersStore.all;
  const { data: slideshows } = slideshowStore.all;
  const { data: current } = currentStore;
  const { data: user } = userStore;
  const { data: slideshow } = slideshowStore.slideshow;
  const { states: newSlideShowDataDataStates } = slideshowStore.new;
  const { states: updateSlideShowStates } = slideshowStore.update;
  const { states: getSlideShowStates } = slideshowStore.slideshow;
  const { states: deleteSlideShowStates } = slideshowStore.delete;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: units } = unitStore.allUnits;
  return {
    cohorts,
    slideshowGetStates,
    slideshowUpdateStates,
    users,
    slideshowDeleteStates,
    slideshows,
    current,
    newSlideShowDataDataStates,
    user,
    slideshow,
    newSlideShowDataStates,
    newSlideShowData,
    updateSlideShowStates,
    getSlideShowStates,
    deleteSlideShowStates,
    units,
  };
};

export default connect(states)(Component);
