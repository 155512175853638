import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { AiFillEdit, AiFillLock, AiFillUnlock } from "react-icons/ai";
import { FaCloudUploadAlt, FaSave, FaShare } from "react-icons/fa";
import Submit from "../../../../components/Form/Submit";
import Grid from "../../../../components/Grid";
import { FlexContainer } from "../../../../components/StyledComponents";
import {
  schema,
  initialValues,
} from "../../../../constants/form/listen/listen-creator";
import Input from "./components/Field/index";
import TextArea from "./components/TextArea";
import { RiCloseCircleFill } from "react-icons/ri";
import {
  ButtonInput,
  TrianglePlay,
  ListenPreviewPublished,
  ListenUploaderDescriptionWrapper,
  ListenUploaderTitle,
  ListenUploadWrapper,
} from "./style";
import { BiLink } from "react-icons/bi";
import { BsFillTrash2Fill } from "react-icons/bs";
import { FiUpload } from "react-icons/fi";
import { HiUserAdd } from "react-icons/hi";
import { toast } from "react-toastify";
import { hideModal, showModal } from "../../../../store/actions/modal";
import { connect, useDispatch } from "react-redux";
import { ListenDto } from "../../../../types/listen.dto";
import SideBar from "./components/SideBar";
import Loader from "../../../../components/Loader";
import {
  deleteListen,
  getAllListens,
  getListen,
  newListen,
  resetDeleteListen,
  resetGetListen,
  resetNewListen,
  resetUpdateListen,
  updateListen,
} from "../../../../store/actions/listen";
import { useNavigate, useParams } from "react-router-dom";
import { StateDto } from "../../../../types/states.dto";
import Button from "../../../../components/Button";
import PublishListenModal from "./components/PublishListenModal";
import { SET_GET_LISTEN } from "../../../../store/types/listen";
import AddUsersModal from "./components/AddUsersModal";
import { UsersDto } from "../../../../types/users.dto";
import ShareModal from "./components/ShareModal";
import PrivateModal from "./components/PrivateModal";
import ModalConfirmDelete from "../../../../components/ModalConfirmDelete";
import { CurrentDto } from "../../../../types/current.dto";
import CustomPlayer from "./components/CustomPlayer";
import { setFormData } from "../../../../helpers/formData";
import DropzoneField from "../../../../components/Form/Dropzone";
import { CohortDto } from "../../../../types/cohort.dto";
import {
  getAllCohorts,
  resetGetAllCohortsData,
} from "../../../../store/actions/cohort";
import { getAllUnits, resetGetAllUnits } from "../../../../store/actions/unit";
const Component = ({
  listen,
  listenGetStates,
  listenUpdateStates,
  users,
  listenDeleteStates,
  listens,
  current,
  newListenDataStates,
  user,
  newListenData,
  cohorts,
}: {
  listen: ListenDto;
  listenGetStates: StateDto;
  listenUpdateStates: StateDto;
  users: UsersDto[];
  listenDeleteStates: StateDto;
  listens: ListenDto[];
  current: CurrentDto;
  newListenDataStates: StateDto;
  user: UsersDto;
  newListenData: ListenDto;
  cohorts: CohortDto[];
}) => {
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const [listenImage, setListenImage] = useState<any>();
  const [listenCurrent, setListenCurrent] = useState<any>();
  const [listenDataChanged, setListenDataChanged] = useState<any>({
    name: "",
    description: "",
  });
  const [listenMedia, setListenMedia] = useState<{
    listenApi?: string;
    imageApi?: string;
  }>({ listenApi: undefined, imageApi: undefined });
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref: any = useRef(null);

  const onSubmitPatch = (values, actions) => {
    dispatch(
      updateListen({
        _id: listen?._id,
        body: setFormData(
          {
            name: values.name,
            description: values.description,
            ...(!!values.picture &&
              !listenMedia.imageApi && { picture: values.picture }),
            ...(!!values.audio && { audio: values.audio }),
            __v: listen?.__v,
          },
          ["picture", "audio"]
        ),
      })
    );
  };
  const onSubmitPost = (values, actions) => {
    if (!listen?.audio && !listenCurrent) return null;
    if (!listen?.picture && !listenImage) return null;
    dispatch(
      newListen(
        setFormData(
          {
            name: values.name,
            description: values.description,
            type: "LISTEN",
            ...(!!listenImage && { picture: listenImage.file }),
            ...(!!listenCurrent && { audio: listenCurrent.file }),
            academy: current.id,
            ...values,
          },
          ["picture", "audio"]
        )
      )
    );
  };

  const onSelectListen = (files) => {
    if (files.length <= 0) {
      setListenCurrent(undefined);
    }

    // I've kept this example simple by using the first image instead of multiple
    setListenCurrent({
      file: files[0],
      preview: URL.createObjectURL(files[0]),
    });
  };

  const onSelectCover = (files) => {
    if (files.length <= 0) {
      setListenImage(undefined);
    }

    // I've kept this example simple by using the first image instead of multiple
    setListenImage({
      file: files[0],
      preview: URL.createObjectURL(files[0]),
    });
  };

  const setChangedListenData = (e) => {
    setListenDataChanged((state) => {
      return { ...state, [e.target.name]: e.target.value };
    });
  };

  const menu = [
    {
      listenRole: ["OWNER"],
      Icon: FiUpload,
      toolTipName: !!listen?.published
        ? "Editar publicacion"
        : "Publicar Listen",
      onClick: () => {
        dispatch(showModal("publish-listen-modal"));
      },
      IconActive: AiFillEdit,
      activeFunction: () => !!listen?.published,
      onClickActive: () => {
        dispatch(showModal("publish-listen-modal"));
      },
    },
    {
      Icon: AiFillLock,
      listenRole: ["OWNER"],
      IconActive: AiFillUnlock,
      toolTipName: "Cambiar visibilidad",
      onAvailable: () => !!listen && !!listen?.published,
      activeFunction: () => !!listen?.published,
      onClick: () => {
        dispatch(showModal("private-listen"));
      },
      onClickActive: () => {
        dispatch(showModal("private-listen"));
      },
    },
    {
      Icon: HiUserAdd,
      listenRole: ["OWNER", "COOWNER"],
      toolTipName: "Añadir Usuarios",
      onAvailable: () => !!listen && !!listen?.published,
      onClick: () => {
        dispatch(showModal("add-user-listen"));
      },
    },
    {
      Icon: FaSave,
      toolTipName: "Guardar",
      listenRole: ["OWNER", "COOWNER"],
      onClick: () => {},
      activeFunction: () =>
        (!listen &&
          listenDataChanged.name?.length > 5 &&
          listenDataChanged.description?.length > 0 &&
          !!listenImage &&
          !!listenCurrent) ||
        (!!listen &&
          listenDataChanged.name?.length > 5 &&
          listenDataChanged.description?.length > 0 &&
          (listen?.name !== listenDataChanged.name ||
            listen?.description !== listenDataChanged.description ||
            (!!listenCurrent && listen?.audio) ||
            (!!listenImage && listen?.picture))),
      onAvailable: () =>
        (!listen &&
          listenDataChanged.name?.length > 5 &&
          listenDataChanged.description?.length > 0 &&
          !!listenImage &&
          !!listenCurrent) ||
        (!!listen &&
          listenDataChanged.name?.length > 5 &&
          listenDataChanged.description?.length > 0 &&
          (listen?.name !== listenDataChanged.name ||
            listen?.description !== listenDataChanged.description ||
            (!!listenCurrent && listen?.audio) ||
            (!!listenImage && listen?.picture))),
      onClickActive: () => {
        if (!!listen) {
          onSubmitPatch(
            {
              name: listenDataChanged.name,
              description: listenDataChanged.description,
              _v: listen?.__v,
              ...(!!listenCurrent && { audio: listenCurrent.file }),
              ...(!!listenImage && { picture: listenImage.file }),
            },
            {}
          );
        } else {
          onSubmitPost(
            {
              name: listenDataChanged.name,
              description: listenDataChanged.description,
              available: false,
              published: false,
            },
            {}
          );
        }
      },
    },
    {
      Icon: BiLink,
      listenRole: ["OWNER", "COOWNER", "READER"],
      toolTipName: "Copiar Link",
      onAvailable: () => !!listen && !!listen?.available && !!listen?.published,
      onClick: () => {
        navigator.clipboard.writeText(
          `https://academia.batan.coop/dashboard/listens/${listen?._id}`
        );
        toast.success(`Link copiado`);
      },
    },
    {
      Icon: FaShare,
      toolTipName: "Compartir",
      listenRole: ["OWNER", "COOWNER", "READER"],
      onAvailable: () => !!listen && !!listen?.available && !!listen?.published,
      onClick: () => {
        dispatch(showModal("share-user-listen"));
      },
    },
    {
      Icon: BsFillTrash2Fill,
      listenRole: ["OWNER"],
      toolTipName: "Eliminar Listen",
      onAvailable: () => !!listen,
      onClick: () => {
        dispatch(showModal("confirm-delete-item-secuencial-modal"));
      },
    },
  ];

  useEffect(() => {
    !cohorts &&
      fetchOn &&
      dispatch(
        getAllCohorts({ filterBy: { academy: current.id, active: true } })
      );
  }, [cohorts, fetchOn]);

  useEffect(() => {
    if (cohorts && fetchOn) {
      dispatch(
        getAllUnits({
          filterBy: { cohort: { $in: cohorts.map((cohort) => cohort._id) } },
        })
      );
    }
  }, [cohorts]);
  useEffect(() => {
    if (!cohorts) {
      setFetchOn(true);
    }
  }, [cohorts]);

  useEffect(() => {
    setListenCurrent(undefined);
    setListenImage(undefined);
    dispatch({ type: SET_GET_LISTEN, response: null });
  }, []);

  useEffect(() => {
    if (!!params.id && !listen) {
      dispatch(getListen({ _id: params.id }));
    }
    if (!params.id && !!listen) {
      dispatch({ type: SET_GET_LISTEN, response: null });
    }
  }, [dispatch, params.id, listen]);

  useEffect(() => {
    if (listenUpdateStates.success) {
      toast.success("👌🏼 Listen actualizado");
      setListenCurrent(undefined);
      setListenImage(undefined);
      dispatch(getListen({ _id: listen?._id }));
      setTimeout(() => {
        dispatch(resetUpdateListen());
        dispatch(hideModal());
        formActions?.resetForm();
      }, 1000);
    }

    if (listenUpdateStates.error) {
      toast.error(`😱 ${listenUpdateStates.error}`);
      dispatch(getListen({ _id: listen?._id }));
      setTimeout(() => {
        dispatch(resetUpdateListen());
      }, 1000);
    }
  }, [listenUpdateStates]);

  useEffect(() => {
    if (listenGetStates.success) {
      setTimeout(() => {
        dispatch(resetGetListen());
      }, 1000);
    }
    if (listenGetStates.error) {
      toast.error(listenGetStates.error);
      setTimeout(() => {
        dispatch(resetGetListen());
      }, 1000);
    }
  }, [listenGetStates]);

  useEffect(() => {
    if (!!listen) {
      setListenDataChanged({
        name: listen?.name,
        description: listen?.description,
      });
      setListenMedia({
        listenApi: listen?.audio,
        imageApi: listen?.picture,
      });
    } else {
      setListenDataChanged({ name: "", description: "" });
      setListenMedia({ listenApi: undefined, imageApi: undefined });
    }
  }, [listen]);

  useEffect(() => {
    if (newListenDataStates.success) {
      toast.success("Se creo el listen exitosamente");
      setListenCurrent(undefined);
      setListenImage(undefined);
      if (!!newListenData) {
        navigate(newListenData._id);
      }
      setTimeout(() => {
        dispatch(resetNewListen());
      }, 1000);
    }
    if (newListenDataStates.error) {
      toast.error("No se pudo crear el listen");
      setTimeout(() => {
        dispatch(resetNewListen());
      }, 1000);
    }
  }, [newListenDataStates, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCohortsData());
      dispatch(resetGetAllUnits());
    };
  }, []);

  return (
    <>
      {(!!params.id && !listen) || listenGetStates.loading ? (
        <Loader color="Primary"></Loader>
      ) : (
        <>
          <Grid.Container>
            <Formik
              initialValues={initialValues({
                name: listenDataChanged.name,
                description: listenDataChanged.description,
              })}
              onSubmit={(values, actions) => {
                if (!!listen) {
                  onSubmitPatch(values, actions);
                } else {
                  onSubmitPost(
                    { ...values, available: true, published: true },
                    actions
                  );
                }
              }}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={schema}
              validateOnMount={false}
              enableReinitialize
            >
              {({
                touched,
                errors,
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                resetForm,
                setFieldValue,
                isSubmitting,
              }) => {
                return (
                  <form
                    className="theme-form"
                    onSubmit={(event) => {
                      if (
                        values.description.length < 5 ||
                        values.name.length < 5
                      ) {
                        toast.error(
                          "La longitud de la descripcion y el titulo tienen que ser mayor a 5 caracteres"
                        );
                      }
                      if (!listenImage && !listen?.picture) {
                        toast.error(
                          "Se necesita subir una imagen de portada para el listen"
                        );
                      }
                      if (!listen?.audio && !listenCurrent) {
                        toast.error("Se necesita subir un listen");
                      }
                      setFormSubmmited(true);
                      handleSubmit(event);
                    }}
                    id="listen-player-create"
                  >
                    <FlexContainer gap="10px">
                      <div style={{ width: "calc(100% - 50px)" }}>
                        <FlexContainer direction="column" gap="10px">
                          {!listenMedia.listenApi ? (
                            !listenCurrent ? (
                              <DropzoneField
                                name="listenSource"
                                onChange={(files) => {
                                  toast.info(
                                    "Los unicos formatos que se pueden previsualizar antes de crearse son mp4-3gpp-ogg-mpeg"
                                  );
                                  onSelectListen(files);
                                }}
                                onBlur={handleBlur}
                                error={errors["listenSource"]}
                                touched={touched["listenSource"]}
                                placeholder={
                                  "Selecciona el archivo que quieras subir"
                                }
                                options={{ size: "big", openOnClick: true }}
                                validation={{
                                  maxFileSize: 1024,
                                  maxFiles: 1,
                                  accept:
                                    "audio/3gpp,audio/ogg,audio/mpeg,audio/mp4,audio/mp3",
                                }}
                              />
                            ) : (
                              <CustomPlayer
                                listenCurrent={listenCurrent}
                                ref={ref}
                                playerHeight="419px"
                                listen={listenCurrent.preview}
                                CustomPlay={TrianglePlay}
                                lightImage={
                                  "https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8&w=1000&q=80"
                                }
                                {...(!!listenImage && {
                                  lightImage: listenImage.preview,
                                })}
                                {...(!!listenMedia.imageApi && {
                                  lightImage: listenMedia.imageApi,
                                })}
                              >
                                {!!listen && !listen?.published && (
                                  <ListenPreviewPublished>
                                    El listen no ha sido publicado
                                  </ListenPreviewPublished>
                                )}
                                <RiCloseCircleFill
                                  onClick={() => {
                                    setListenCurrent(undefined);
                                  }}
                                  size={25}
                                  fill={"#E91E63"}
                                  color={"#fff"}
                                  style={{
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    zIndex: 3,
                                  }}
                                ></RiCloseCircleFill>
                              </CustomPlayer>
                            )
                          ) : (
                            <CustomPlayer
                              listenCurrent={listenCurrent}
                              ref={ref}
                              playerHeight="419px"
                              listen={listenMedia.listenApi}
                              CustomPlay={TrianglePlay}
                              lightImage={
                                "https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8&w=1000&q=80"
                              }
                              {...(!!listenImage && {
                                lightImage: listenImage.preview,
                              })}
                              {...(!!listenMedia.imageApi && {
                                lightImage: listenMedia.imageApi,
                              })}
                            >
                              {!!listen && !listen?.published && (
                                <ListenPreviewPublished>
                                  El listen no ha sido publicado
                                </ListenPreviewPublished>
                              )}
                            </CustomPlayer>
                          )}
                          <ListenUploaderDescriptionWrapper>
                            <FlexContainer
                              justify="space-between"
                              wrap="wrap"
                              gap="10px"
                            >
                              <FlexContainer direction="column" gap="12px">
                                <FlexContainer
                                  direction="column"
                                  gap="12px"
                                  style={{ width: "360px" }}
                                >
                                  <Input
                                    containerStyles={{
                                      width: "100%",
                                      maxWidth: "340px",
                                      borderColor: "#697482",
                                    }}
                                    name="name"
                                    touched={touched["name"]}
                                    value={values["name"]}
                                    type="text"
                                    placeholder="Nombre del Listen"
                                    onChange={(ev) => {
                                      setChangedListenData(ev);
                                      handleChange(ev);
                                    }}
                                    onBlur={handleBlur}
                                    options={{
                                      marginBottom: 1,
                                    }}
                                  />
                                  <TextArea
                                    containerStyles={{
                                      width: "100%",
                                      maxWidth: "340px",
                                    }}
                                    name="description"
                                    touched={touched["description"]}
                                    value={values["description"]}
                                    onChange={(ev) => {
                                      setChangedListenData(ev);
                                      handleChange(ev);
                                    }}
                                    placeholder="Escriba su descripcion"
                                    onBlur={handleBlur}
                                    options={{
                                      skin: "base",
                                      marginBottom: 1,
                                    }}
                                  />
                                  <FlexContainer
                                    gap="10px"
                                    align="center"
                                    wrap="wrap"
                                  >
                                    {
                                      <>
                                        {!listen && (
                                          <>
                                            <Submit
                                              isSubmmiting={
                                                listenUpdateStates.loading
                                              }
                                              form="listen-player-create"
                                              color="Primary"
                                              options={{
                                                type: "filled",
                                                skin: "primary",
                                                size: "lg",
                                              }}
                                            >
                                              Publicar Rapido
                                            </Submit>
                                            <ButtonInput
                                              style={{
                                                ...(!listenCurrent && {
                                                  cursor: "not-allowed",
                                                }),
                                                backgroundColor: `${
                                                  !listenCurrent
                                                    ? "grey"
                                                    : "rgb(21, 115, 229)"
                                                }`,
                                              }}
                                            >
                                              Subir Otro Listen
                                              {!!listenCurrent && (
                                                <input
                                                  accept="audio/3gpp,audio/ogg,audio/mpeg,audio/mp4,audio/mp3"
                                                  onChange={(ev) => {
                                                    toast.info(
                                                      "Los unicos formatos que se pueden previsualizar antes de crearse son 3gpp-ogg-mpeg-mp4-mp3,"
                                                    );
                                                    if (!!listenCurrent) {
                                                      onSelectListen(ev);
                                                    }
                                                  }}
                                                  type="file"
                                                  style={{
                                                    visibility: "hidden",
                                                    width: "0",
                                                    height: "0",
                                                  }}
                                                ></input>
                                              )}
                                            </ButtonInput>
                                          </>
                                        )}
                                        {((!!listen &&
                                          (listen?.users.find(
                                            (userFounded) =>
                                              !userFounded.roleEnd &&
                                              userFounded.id === user._id
                                          )?.role === "OWNER" ||
                                            listen?.users.find(
                                              (userFounded) =>
                                                !userFounded.roleEnd &&
                                                userFounded.id === user._id
                                            )?.role === "COOWNER")) ||
                                          !listen) && (
                                          <Button
                                            onClick={() => {
                                              dispatch(
                                                showModal(
                                                  "publish-listen-modal"
                                                )
                                              );
                                            }}
                                            type="button"
                                            options={{
                                              type: "filled",
                                              skin: "primary",
                                              size: "lg",
                                            }}
                                            style={{ marginLeft: "10px" }}
                                          >
                                            Publicar
                                          </Button>
                                        )}
                                      </>
                                    }
                                  </FlexContainer>
                                </FlexContainer>
                              </FlexContainer>
                              {!listenImage && !listenMedia.imageApi ? (
                                <DropzoneField
                                  name="listenImg"
                                  onChange={(files) => {
                                    onSelectCover(files);
                                  }}
                                  onBlur={handleBlur}
                                  error={errors["listenImg"]}
                                  touched={touched["listenImg"]}
                                  placeholder={
                                    "Subir imagen de Preview de listen"
                                  }
                                  options={{ size: "small", openOnClick: true }}
                                  validation={{
                                    maxFileSize: 1024,
                                    maxFiles: 1,
                                    accept: "image/*",
                                  }}
                                />
                              ) : (
                                <ListenUploadWrapper
                                  height={"200px"}
                                  style={{
                                    width: "340px",
                                    ...(!!listenImage && {
                                      backgroundImage: `url(${listenImage.preview})`,
                                    }),
                                    ...(!!listenMedia.imageApi && {
                                      backgroundImage: `url(${listenMedia.imageApi})`,
                                    }),
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center center",
                                  }}
                                >
                                  <RiCloseCircleFill
                                    onClick={() => {
                                      setListenImage(undefined);
                                      setListenMedia((state) => {
                                        return {
                                          ...state,
                                          imageApi: undefined,
                                        };
                                      });
                                    }}
                                    size={25}
                                    fill={"#E91E63"}
                                    color={"#fff"}
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      right: "10px",
                                    }}
                                  ></RiCloseCircleFill>
                                </ListenUploadWrapper>
                              )}
                            </FlexContainer>
                          </ListenUploaderDescriptionWrapper>
                        </FlexContainer>
                      </div>
                      <SideBar listen={listen} menu={menu}></SideBar>
                    </FlexContainer>
                  </form>
                );
              }}
            </Formik>
          </Grid.Container>

          <PublishListenModal
            listenDataChanged={listenDataChanged}
            listen={listen}
            listenImage={listenImage}
            listenCurrent={listenCurrent}
          ></PublishListenModal>

          {!!listen && <AddUsersModal listen={listen}></AddUsersModal>}
          {!!listen && <ShareModal listen={listen}></ShareModal>}
          {!!listen && (
            <PrivateModal
              listen={{
                _id: listen?._id,
                published: listen?.published,
                name: listenDataChanged.name,
                description: listenDataChanged.description,
                __v: listen?.__v,
              }}
            ></PrivateModal>
          )}
          {!!listen && (
            <ModalConfirmDelete
              bntConfig={{
                content: "Eliminar",
                style: {
                  style: { width: "170px", height: "40px" },
                  color: "Danger",
                  options: {
                    type: "filled",
                    skin: "danger",
                    size: "lg",
                    marginBottom: "0px",
                  },
                },
              }}
              sucessAction={() => navigate("/dashboard/contents/listens")}
              states={listenDeleteStates}
              description={`El Listen se eliminara para siempre del sistema.`}
              title={
                <span
                  style={{
                    color: "#697482",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  {`¿Seguro que quiere eliminar a ${listen?.name}?`}
                </span>
              }
              elementActions={deleteListen({ _id: listen?._id })}
              resetAction={resetDeleteListen}
              resetState={() => {}}
            ></ModalConfirmDelete>
          )}
        </>
      )}
    </>
  );
};

const states = ({
  listenStore,
  usersStore,
  currentStore,
  userStore,
  cohortStore,
}) => {
  const { data: listen } = listenStore.listen;
  const { states: listenGetStates } = listenStore.listen;
  const { states: listenUpdateStates } = listenStore.update;
  const { states: listenDeleteStates } = listenStore.delete;
  const { data: users } = usersStore.all;
  const { data: listens } = listenStore.all;
  const { data: current } = currentStore;
  const { data: user } = userStore;
  const { states: newListenDataStates, data: newListenData } = listenStore.new;
  const { data: cohorts } = cohortStore.allCohorts;
  return {
    listen,
    listenGetStates,
    listenUpdateStates,
    users,
    listenDeleteStates,
    listens,
    current,
    newListenDataStates,
    user,
    newListenData,
    cohorts,
  };
};

export default connect(states)(Component);
