import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import { showModal } from "../../../store/actions/modal";
import Constana from "../../../assets/images/constana.png";
import Email from "../../../assets/images/email.png";
import Grid from "../../../components/Grid";
import { Center, FlexContainer } from "../../../components/StyledComponents";
import Submit from "../../../components/Form/Submit";
import Button from "../../../components/Button";
import { connect, useDispatch } from "react-redux";

import invitationActions from "../../../store/actions/invitation";
import { Formik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import Reject from "../../../assets/images/reject.png";
import MailCampus from "../../../assets/images/mailviolet.png";
import Accepted from "../../../assets/images/accepted.png";
import CampusLogo from "../../../assets/images/logocampuspic.png";
import CampusLogoPic from "../../../assets/images/logocampus.png";
import { InvitationDto } from "../../../types/invitation.dto";
import { Img } from "../Cohort/Customize/style";
import { ToastContainer, toast } from "react-toastify";
import { StateDto } from "../../../types/states.dto";
const Component = ({
  invitation,
  updateStates,
  getStates,
}: {
  invitation: InvitationDto;
  updateStates: StateDto;
  getStates: StateDto;
}) => {
  const [accepted, setAccepted] = useState<boolean | null>(null);
  const [condition, setCondition] = useState<string>();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const campus = "https://academia.batan.coop";
  const constana = "https://academy.academia.batan.coop";
  const knowProperty = (properties: string[], object: InvitationDto) => {
    const propertyFromObject = properties.filter((property) =>
      object.hasOwnProperty(property)
    )[0];
    return propertyFromObject;
  };
  const onSubmit = ({ values, actions }) => {
    setCondition("accept");
    dispatch(
      invitationActions.update({
        _id: searchParams.get("token"),
        condition: "accept",
      })
    );
    setFormActions(actions);
  };
  useEffect(() => {
    dispatch(showModal("invitation-mail"));
  }, []);
  useEffect(() => {
    dispatch(invitationActions.getOne({ _id: searchParams.get("token") }));
  }, [searchParams]);
  useEffect(() => {
    if (updateStates.success && !!condition) {
      setAccepted(condition === "accept");
      setTimeout(() => {
        dispatch(invitationActions.resetUpdate());
      }, 1000);
    }
    if (updateStates.error) {
      toast.error(updateStates.error);
      setTimeout(() => {
        dispatch(invitationActions.resetUpdate());
      }, 1000);
    }
  }, [updateStates]);

  useEffect(() => {
    if (getStates.success) {
      setTimeout(() => {
        dispatch(invitationActions.resetGetOne());
      }, 1000);
    }
    if (getStates.error) {
      toast.error(`${getStates.error}`);
      setTimeout(() => {
        dispatch(invitationActions.resetGetOne());
      }, 1000);
    }
  }, [getStates]);

  return (
    <div>
      <Modal name="invitation-mail" remove>
        {!invitation ? (
          <Loader color="Primary" />
        ) : accepted !== null ? (
          <>
            <div style={{ position: "absolute", left: "0px" }}>
              <FlexContainer gap="5px" align="center">
                {invitation.role === "STUDENT" && (
                  <Img src={CampusLogoPic}></Img>
                )}
                <Img
                  alt="constana-icon"
                  src={invitation.role === "STUDENT" ? CampusLogo : Constana}
                ></Img>
              </FlexContainer>
            </div>
            <Grid.Row style={{ marginTop: "66px", marginBottom: "93px" }}>
              <Center>
                <h2>
                  {accepted === true
                    ? `¡Has confirmado la invitación para ${
                        invitation[
                          knowProperty(
                            ["academy", "cohort", "organization", "team"],
                            invitation
                          )
                        ].name
                      }!`
                    : `¡Has rechazado la invitación para ${
                        invitation[
                          knowProperty(
                            ["academy", "cohort", "organization", "team"],
                            invitation
                          )
                        ].name
                      }!`}
                </h2>
              </Center>
            </Grid.Row>
            <Grid.Row style={{ marginBottom: "125px" }}>
              <Center>
                <div>
                  <img
                    src={accepted === true ? Accepted : Reject}
                    alt="status-logo"
                  />
                </div>
              </Center>
            </Grid.Row>
            <Grid.Row>
              <Center>
                <Button
                  onClick={() => {
                    if (!!accepted) {
                      if (invitation.cohort) {
                        if (invitation.role === "STUDENT") {
                          window.open(
                            `${campus}/dashboard/cohort/${invitation.cohort._id}/home`
                          );
                        }
                        if (invitation.role === "PROFESSOR") {
                          window.open(`${constana}/invite?`);
                        }
                      }
                      if (invitation.academy) {
                        if (invitation.role === "STUDENT")
                          window.open(
                            `${campus}/dashboard/academy/${invitation.academy._id}/home`
                          );
                        else {
                          window.open(
                            `${constana}/invite?academy=${invitation.academy._id}`
                          );
                        }
                      }
                      if (invitation.organization) {
                        window.open(
                          `${constana}/invite?organization=${invitation.organization._id}`
                        );
                      }
                    } else {
                      window.open(
                        invitation.role === "STUDENT" ? campus : constana
                      );
                    }
                  }}
                  type="button"
                  options={{
                    type: "filled",
                    skin: `${
                      invitation.role === "STUDENT" ? "violet" : "primary"
                    }`,
                    size: "lg",
                    marginBottom: "0px",
                  }}
                  style={{ marginLeft: "10px", width: "100%" }}
                >
                  {invitation.role === "STUDENT"
                    ? "Ir a Constana Campus"
                    : "Ir a Constana"}
                </Button>
              </Center>
            </Grid.Row>
          </>
        ) : invitation.status !== "PENDING" || !invitation.active ? (
          <>
            <Grid.Row style={{ marginTop: "66px", marginBottom: "93px" }}>
              <Center>
                <h2>La invitacion ya fue utilizada</h2>
              </Center>
            </Grid.Row>
            <Grid.Row style={{ marginBottom: "125px" }}>
              <Center>
                <div>
                  <img src={Reject} alt="status-logo" />
                </div>
              </Center>
            </Grid.Row>
            <Grid.Row>
              <Center>
                <Button
                  onClick={() => {
                    const platform =
                      invitation.role === "STUDENT"
                        ? "https://academia.batan.coop"
                        : "https://academy.academia.batan.coop";
                    if (invitation.role === "STUDENT") {
                      if (invitation.cohort) {
                        window.open(
                          `${platform}/dashboard/cohort/${invitation.cohort._id}/home`
                        );
                      }
                      if (invitation.academy) {
                        window.open(
                          `${platform}/dashboard/academy/${invitation.academy._id}/home`
                        );
                      }
                    } else {
                      window.open(`${platform}/sign-in`);
                    }
                  }}
                  type="button"
                  options={{
                    type: "filled",
                    skin: `${
                      invitation.role === "STUDENT" ? "violet" : "primary"
                    }`,
                    size: "lg",
                    marginBottom: "0px",
                  }}
                  style={{ marginLeft: "10px", width: "100%" }}
                >
                  {invitation.role === "STUDENT"
                    ? "Ir a Constana Campus"
                    : "Ir a Constana"}
                </Button>
              </Center>
            </Grid.Row>
          </>
        ) : (
          <>
            <Grid.Row style={{ marginTop: "66px", marginBottom: "93px" }}>
              <Center>
                <h2>{`¡Tienes una invitación de ${invitation.sentBy.name} ${invitation.sentBy.lastName}!`}</h2>
              </Center>
            </Grid.Row>
            <Grid.Row style={{ marginBottom: "125px" }}>
              <Center>
                <div>
                  <img
                    src={invitation.role === "STUDENT" ? MailCampus : Email}
                    alt="email-logo"
                  />
                </div>
              </Center>
            </Grid.Row>
            <Formik
              initialValues={{}}
              onSubmit={(values, actions) => {
                if (onSubmit) onSubmit({ values, actions });
              }}
              validateOnChange={formSubmmited}
              validateOnBlur={false}
              enableReinitialize
            >
              {({
                touched,
                errors,
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                resetForm,
                setFieldValue,
                isSubmitting,
              }) => {
                return (
                  <form
                    className="theme-form"
                    onSubmit={(event) => {
                      setFormSubmmited(true);
                      handleSubmit(event);
                    }}
                    id="edit-event-form"
                  >
                    <Grid.Row>
                      <Grid.Col>
                        <Center gap={"70px"}>
                          {invitation.role === "STUDENT" ? (
                            <Submit
                              isSubmmiting={updateStates.loading}
                              form="edit-event-form"
                              color="Primary"
                              options={{
                                type: "filled",
                                skin: "violet",
                                loading: "Violet",
                                size: "lg",
                              }}
                              style={{ height: "26px" }}
                            >
                              Confirmar
                            </Submit>
                          ) : (
                            <Submit
                              isSubmmiting={updateStates.loading}
                              form="edit-event-form"
                              color="Primary"
                              options={{
                                type: "filled",
                                skin: "primary",
                                loading: "Primary",
                                size: "lg",
                              }}
                            >
                              Confirmar
                            </Submit>
                          )}

                          <Button
                            onClick={() => {
                              setCondition("reject");
                              dispatch(
                                invitationActions.update({
                                  _id: searchParams.get("token"),
                                  condition: "reject",
                                })
                              );
                            }}
                            disabled={updateStates.loading}
                            type="button"
                            options={{
                              type: "filled",
                              skin: "danger",
                              size: "lg",
                              marginBottom: "0px",
                            }}
                            style={{
                              marginLeft: "10px",
                              ...((!!invitation?.cohort ||
                                invitation.role === "STUDENT") && {
                                height: "26px",
                              }),
                            }}
                          >
                            Rechazar
                          </Button>
                        </Center>
                      </Grid.Col>
                    </Grid.Row>
                  </form>
                );
              }}
            </Formik>
          </>
        )}
      </Modal>
      <ToastContainer limit={1}></ToastContainer>
    </div>
  );
};

const states = ({ invitationStore }) => {
  const { states: getStates, data: invitation } = invitationStore.invitation;
  const { states: updateStates } = invitationStore.update;
  return {
    invitation,
    updateStates,
    getStates,
  };
};

export default connect(states)(Component);
