import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";

import { checkPermission } from "../components/PermissionsChecker";

import AuthProvider from "../provider/AuthProvider";
import ThemeProvider from "../provider/ThemeProvider";
import CurrentProvider from "../provider/CurrentProvider";
import RecruitingProvider from "../provider/RecruitingProvider";
import DashboardProvider from "../provider/DashboardProvider";
import RecruitingLayout from "../components/Layout/Recuiting";
import AIT from "./Dashboard/Recruiting/AIT";

import ScrollToTop from "../components/ScrollTop";
import RequireAuth from "../components/RequireAuth";

import SignIn from "./SignIn";

import Page404 from "./NotFound";
import InDeveloping from "./InDevolping/index";

import DashboardLayout from "../components/Layout/Dashboard";
import AuthLayout from "../components/Layout/Auth";

import Marketplace from "./Dashboard/Marketplace";
import Analytics from "./Dashboard/Analytics";

import Calendar from "./Dashboard/Calendar";
import SignUp from "./SignUp";

import ProfileUser from "./Dashboard/User/Profile/index";

import Recruiting from "./Dashboard/Recruiting/index";

import Talents from "./Dashboard/Talents/index";
import Gestion from "./Dashboard/Academy/Gestion";
import { connect } from "react-redux";
import { UserDto } from "../types/user.dto";
import { CurrentDto } from "../types/current.dto";
import ProfileEntity from "./Dashboard/Academy/Profile/index";

import Evaluate from "./Dashboard/Evaluate/index";
import Criterions from "./Dashboard/Criterion/index";
import SubCriterions from "./Dashboard/Subcriterion/index";
import Cohort from "./Dashboard/Cohort";
import NotificationEmail from "./Dashboard/NotificationEmail";
import ConfirmationAuth from "./Dashboard/ConfirmationAuth";
import Content from "./Dashboard/Content";
import Template from "./Dashboard/Certificates/Template";
import Notification from "./Dashboard/Notification";
import DashboardAcademy from "./Dashboard/Dashboard/Academy/index";
import DashboardAcademyConstanaGeneral from "./Dashboard/Dashboard/Academy/Constana/General/index";
import DashboardAcademyJob from "./Dashboard/Dashboard/Academy/Constana/Job";
import DashboardAcademyAudience from "./Dashboard/Dashboard/Academy/Campus/Audience";
import DashboardAcademyCampusGeneral from "./Dashboard/Dashboard/Academy/Campus/General";

import { GoogleOAuthProvider } from "@react-oauth/google";
import DashboardOrganization from "./Dashboard/Dashboard/Organization";
import DashboardOrganizationGeneral from "./Dashboard/Dashboard/Organization/General";
import DashboardOrganizationMember from "./Dashboard/Dashboard/Organization/Members";
import DashboardOrganizationTalent from "./Dashboard/Dashboard/Organization/Talents";
import Cdocs from "./Dashboard/Cdocs";
import Video from "./Dashboard/Video";
import Image from "./Dashboard/Image";
import VideoList from "./Dashboard/Video/List";
import VideoCreator from "./Dashboard/Video/Creator";
import CreatorContent from "./Dashboard/Content/Creator";
import RoutePermission from "../components/RoutePermission";
import RoadmapList from "../pages/Dashboard/Roadmap/List";
import RoadmapCreator from "../pages/Dashboard/Roadmap/Creator";
import { ReactFlowProvider } from "reactflow";
import Resolve from "./Dashboard/Invitation/ByName";
import ByEmail from "./Dashboard/Invitation/ByEmail";
import SlideShow from "./Dashboard/SlideShow";
import Monetization from "./Dashboard/Monetization";
import Listen from "./Dashboard/Listen";
import ConditionAndTerms from "./ConditionAndTerms";
import Byte from "./Dashboard/Byte";
import Calification from "./Dashboard/Calification";
import ContentDashboard from "./Dashboard/Dashboard/Academy/Content";
import Management from "./Dashboard/Personal/Management";

interface ComponentProps {
  user: UserDto;
  data: CurrentDto;
}

const Component = ({ user, data }: ComponentProps) => {
  return (
    <AuthProvider>
      <ThemeProvider>
        <ReactFlowProvider>
          <BrowserRouter>
            <GoogleOAuthProvider clientId="675324732875-9eqbe4gtem1er2carmb3qmlhbuuh2doj.apps.googleusercontent.com">
              <ScrollToTop useLocation={useLocation} />
              <CurrentProvider>
                <DashboardProvider useLocation={useLocation}>
                  <Routes>
                    <Route
                      path="/dashboard/*"
                      element={
                        <RequireAuth>
                          <RecruitingProvider>
                            <DashboardLayout />
                          </RecruitingProvider>
                        </RequireAuth>
                      }
                    >
                      {data?.currentSection === "personal" && (
                        <Route
                          index
                          element={<Navigate to={"/dashboard/home"} />}
                        />
                      )}
                      {data?.currentSection === "academy" && (
                        <Route
                          index
                          element={<Navigate to={"contents/video"} />}
                        />
                      )}
                      {data?.currentSection === "organization" && (
                        <Route
                          index
                          element={<Navigate to={"marketplace/list"} />}
                        />
                      )}
                      <Route path="contents">
                        <Route path="video">
                          <Route
                            path=""
                            element={
                              <RoutePermission
                                element={<VideoList></VideoList>}
                                action="route:contents:videos:preview"
                              />
                            }
                          ></Route>
                          <Route
                            path="management"
                            element={<VideoCreator></VideoCreator>}
                          >
                            <Route
                              path=":id"
                              element={<VideoCreator></VideoCreator>}
                            ></Route>
                          </Route>
                        </Route>
                        <Route path="bytes">
                          <Route
                            path=""
                            element={
                              <RoutePermission
                                element={<Byte.List></Byte.List>}
                                action="route:contents:videos:preview"
                              />
                            }
                          ></Route>
                          <Route
                            path="management"
                            element={<Byte.Creator></Byte.Creator>}
                          >
                            <Route
                              path=":id"
                              element={<Byte.Creator></Byte.Creator>}
                            ></Route>
                          </Route>
                        </Route>
                        <Route path="listens">
                          <Route
                            path=""
                            element={
                              <RoutePermission
                                element={<Listen.List></Listen.List>}
                                action="route:contents:listens:preview"
                              />
                            }
                          ></Route>
                          <Route
                            path="management"
                            element={<Listen.Creator></Listen.Creator>}
                          >
                            <Route
                              path=":id"
                              element={<Listen.Creator></Listen.Creator>}
                            ></Route>
                          </Route>
                        </Route>
                        <Route path="images">
                          <Route
                            path=""
                            element={
                              <RoutePermission
                                element={<Image.List></Image.List>}
                                action="route:contents:images:preview"
                              />
                            }
                          ></Route>
                          <Route
                            path="management"
                            element={<Image.Creator></Image.Creator>}
                          >
                            <Route
                              path=":id"
                              element={<Image.Creator></Image.Creator>}
                            ></Route>
                          </Route>
                        </Route>
                        <Route path="cdocs">
                          <Route
                            path=""
                            element={
                              <RoutePermission
                                element={<Cdocs.List></Cdocs.List>}
                                action="route:contents:cdocs:preview"
                              />
                            }
                          ></Route>
                          <Route
                            path="management"
                            element={<Cdocs.Document></Cdocs.Document>}
                          >
                            <Route
                              path=":id"
                              element={<Cdocs.Document></Cdocs.Document>}
                            ></Route>
                          </Route>
                        </Route>
                        <Route path="roadmaps">
                          <Route
                            path=""
                            element={
                              <RoutePermission
                                element={<RoadmapList></RoadmapList>}
                                action="route:contents:roadmaps:preview"
                              />
                            }
                          ></Route>
                          <Route
                            path="management"
                            element={<RoadmapCreator></RoadmapCreator>}
                          >
                            <Route
                              path=":id"
                              element={<RoadmapCreator></RoadmapCreator>}
                            ></Route>
                          </Route>
                        </Route>
                        <Route path="variety">
                          <Route
                            path=""
                            element={
                              <RoutePermission
                                element={<Content></Content>}
                                action="route:contents:variety:preview"
                              />
                            }
                          ></Route>
                          <Route
                            path="management"
                            element={<CreatorContent></CreatorContent>}
                          >
                            <Route
                              path=":id"
                              element={<CreatorContent></CreatorContent>}
                            ></Route>
                          </Route>
                        </Route>
                        <Route path="slideshows">
                          <Route
                            path=""
                            element={
                              <RoutePermission
                                element={<SlideShow.List></SlideShow.List>}
                                action="route:contents:slideshows:preview"
                              />
                            }
                          ></Route>
                          <Route
                            path="management"
                            element={<SlideShow.Creator></SlideShow.Creator>}
                          >
                            <Route
                              path=":id"
                              element={<SlideShow.Creator></SlideShow.Creator>}
                            ></Route>
                          </Route>
                        </Route>
                      </Route>
                      <Route path="cohorts">
                        <Route
                          path="list"
                          element={
                            <RoutePermission
                              element={<Cohort.List></Cohort.List>}
                              action={"route:cohort:list:preview"}
                            ></RoutePermission>
                          }
                        ></Route>
                        <Route
                          path="events"
                          element={
                            <RoutePermission
                              element={<Cohort.Events></Cohort.Events>}
                              action={"route:cohort:events:preview"}
                            ></RoutePermission>
                          }
                        ></Route>
                        <Route
                          path="customize"
                          element={
                            <RoutePermission
                              element={<Cohort.Customize></Cohort.Customize>}
                              action={"route:cohort:customize:preview"}
                            ></RoutePermission>
                          }
                        ></Route>
                      </Route>
                      <Route path="calificates">
                        <Route
                          path="evaluate"
                          element={
                            <RoutePermission
                              element={
                                <Calification.Calificate></Calification.Calificate>
                              }
                              action={"route:calificate:evaluate:preview"}
                            ></RoutePermission>
                          }
                        ></Route>
                        <Route
                          path="criterions"
                          element={
                            <RoutePermission
                              element={<Calification.Criterion />}
                              action={"route:calificate:criterions:create"}
                            ></RoutePermission>
                          }
                        >
                          {/*   <Route path="create"></Route> */}
                          {/* <Route
                            path="edit"
                            element={
                              <RoutePermission
                                element={<Criterions.New />}
                                action={"route:calificate:criterions:edit"}
                              ></RoutePermission>
                            }
                          ></Route> */}
                        </Route>
                        <Route
                          path="subcriterions"
                          element={
                            <RoutePermission
                              element={<Calification.SubCriterion />}
                              action={"route:calificate:subcriterion:create"}
                            ></RoutePermission>
                          }
                        >
                          {/*   <Route
                            path="create"
                            
                          ></Route>
                          <Route
                            path="edit"
                            element={
                              <RoutePermission
                                element={<SubCriterions.Edit />}
                                action={"route:calificate:subcriterion:edit"}
                              ></RoutePermission>
                            }
                          ></Route> */}
                        </Route>
                      </Route>
                      <Route
                        path="home"
                        element={
                          <RoutePermission
                            element={<Management></Management>}
                            action={"route:home:preview"}
                          ></RoutePermission>
                        }
                      ></Route>

                      <Route path="management">
                        <Route path="" element={<Gestion></Gestion>}></Route>
                        <Route
                          path="profile"
                          element={
                            <RoutePermission
                              element={<ProfileEntity />}
                              action={"route:management:profile:preview"}
                            ></RoutePermission>
                          }
                        ></Route>
                        <Route
                          path="members"
                          element={
                            <RoutePermission
                              element={<Gestion />}
                              action={"route:management:members:preview"}
                            ></RoutePermission>
                          }
                        ></Route>
                        <Route path="collaborators">
                          <Route path="invitation" element={<InDeveloping />} />
                          <Route
                            path="management"
                            element={
                              <RoutePermission
                                element={<Gestion />}
                                action={"route:management:profile:preview"}
                              ></RoutePermission>
                            }
                          />
                          <Route path="customize" element={<InDeveloping />} />
                        </Route>
                        <Route path="certificates">
                          <Route
                            path="management"
                            element={
                              <RoutePermission
                                element={<Template />}
                                action={
                                  "route:management:certificate:management:preview"
                                }
                              ></RoutePermission>
                            }
                          />
                        </Route>
                        <Route path="subscribers">
                          <Route
                            path="list"
                            element={
                              <RoutePermission
                                element={<Talents></Talents>}
                                action={
                                  "route:management:suscribers:list:preview"
                                }
                              ></RoutePermission>
                            }
                          ></Route>
                          <Route
                            path="access"
                            element={<InDeveloping />}
                          ></Route>
                        </Route>
                        <Route
                          path="marketplace"
                          element={
                            <RoutePermission
                              element={<Marketplace.List></Marketplace.List>}
                              action={"route:management:marketplace:preview"}
                            ></RoutePermission>
                          }
                        ></Route>
                        <Route
                          path="calendar"
                          element={
                            <RoutePermission
                              element={<Calendar></Calendar>}
                              action={"route:management:calendar:preview"}
                            ></RoutePermission>
                          }
                        ></Route>
                      </Route>
                      <Route
                        path="calendar"
                        element={<Calendar></Calendar>}
                      ></Route>
                      <Route path="dashboard">
                        <Route path="constana">
                          <Route
                            path="general"
                            element={
                              <RoutePermission
                                element={
                                  <DashboardAcademyConstanaGeneral></DashboardAcademyConstanaGeneral>
                                }
                                action={
                                  "route:dashboard:constana:general:preview"
                                }
                              ></RoutePermission>
                            }
                          ></Route>
                          <Route
                            path="jobs"
                            element={
                              <RoutePermission
                                element={
                                  <DashboardAcademyJob></DashboardAcademyJob>
                                }
                                action={"route:dashboard:constana:jobs:preview"}
                              ></RoutePermission>
                            }
                          ></Route>
                        </Route>
                        <Route
                          path="campus"
                          element={<DashboardAcademy></DashboardAcademy>}
                        >
                          <Route
                            path="general"
                            element={
                              <RoutePermission
                                element={
                                  <DashboardAcademyCampusGeneral></DashboardAcademyCampusGeneral>
                                }
                                action={
                                  "route:dashboard:campus:general:preview"
                                }
                              ></RoutePermission>
                            }
                          ></Route>
                          <Route
                            path="audience"
                            element={
                              <RoutePermission
                                element={
                                  <DashboardAcademyAudience></DashboardAcademyAudience>
                                }
                                action={
                                  "route:dashboard:campus:audience:preview"
                                }
                              ></RoutePermission>
                            }
                          ></Route>
                        </Route>
                        <Route path="cohorts">
                          <Route
                            path="cohort"
                            element={<Analytics.BootcampPerformance />}
                          ></Route>
                          <Route
                            path="individual"
                            element={<Analytics.IndividualPerformance />}
                          ></Route>
                        </Route>
                        <Route path="contents">
                          <Route
                            path="cohorts"
                            element={
                              <ContentDashboard.Cohort></ContentDashboard.Cohort>
                            }
                          ></Route>
                          <Route
                            path="general"
                            element={
                              <ContentDashboard.General></ContentDashboard.General>
                            }
                          ></Route>
                        </Route>
                        <Route path="monetization">
                          <Route
                            path="kpis"
                            element={<InDeveloping></InDeveloping>}
                          ></Route>
                          <Route
                            path="earning"
                            element={<InDeveloping></InDeveloping>}
                          ></Route>
                        </Route>
                        <Route
                          path="organization"
                          element={
                            <DashboardOrganization></DashboardOrganization>
                          }
                        >
                          <Route
                            path="general"
                            element={
                              <RoutePermission
                                element={
                                  <DashboardOrganizationGeneral></DashboardOrganizationGeneral>
                                }
                                action={
                                  "route:dashboard:preview:organization:general"
                                }
                              ></RoutePermission>
                            }
                          ></Route>
                          <Route
                            path="members"
                            element={
                              <RoutePermission
                                element={
                                  <DashboardOrganizationMember></DashboardOrganizationMember>
                                }
                                action={
                                  "route:dashboard:preview:organization:members"
                                }
                              ></RoutePermission>
                            }
                          ></Route>
                          <Route
                            path="talents"
                            element={
                              <RoutePermission
                                element={
                                  <DashboardOrganizationTalent></DashboardOrganizationTalent>
                                }
                                action={
                                  "route:dashboard:preview:organization:talents"
                                }
                              ></RoutePermission>
                            }
                          ></Route>
                        </Route>
                      </Route>

                      <Route
                        path="monetization"
                        element={
                          <RoutePermission
                            element={
                              <Monetization.Academy></Monetization.Academy>
                            }
                            action="route:monetization:preview"
                          ></RoutePermission>
                        }
                      ></Route>

                      <Route
                        path="myfinances"
                        element={
                          <RoutePermission
                            element={
                              <Monetization.MyFinance></Monetization.MyFinance>
                            }
                            action="route:myfinances:preview"
                          ></RoutePermission>
                        }
                      ></Route>
                      {checkPermission({
                        role: data?.role,
                        action: "route:profile:preview",
                        section: data?.currentSection,
                      }) && (
                        <Route
                          path="profile"
                          element={<ProfileUser></ProfileUser>}
                        ></Route>
                      )}

                      {checkPermission({
                        role: data?.role,
                        action: "route:table:preview",
                        section: data?.currentSection,
                      }) && <Route path="table" element={<AIT></AIT>}></Route>}

                      <Route
                        path="notification"
                        element={
                          <RoutePermission
                            element={<Notification></Notification>}
                            action={"route:notification:preview"}
                          ></RoutePermission>
                        }
                      ></Route>
                      {checkPermission({
                        role: data?.role,
                        action: "route:settings:preview",
                        section: data?.currentSection,
                      }) && (
                        <Route
                          path="settings"
                          element={<InDeveloping />}
                        ></Route>
                      )}
                      {checkPermission({
                        role: data?.role,
                        action: "route:team:preview",
                        section: data?.currentSection,
                      }) && (
                        <Route path="teams/*">
                          <Route path=":id/*">
                            <Route path="ait" element={<AIT></AIT>}></Route>
                            <Route
                              path="*"
                              element={<RecruitingLayout></RecruitingLayout>}
                            >
                              <Route
                                path="companies"
                                element={
                                  <Recruiting.MyCompanies></Recruiting.MyCompanies>
                                }
                              ></Route>

                              <Route
                                path="searches"
                                element={
                                  <Recruiting.MySearches></Recruiting.MySearches>
                                }
                              ></Route>
                              <Route
                                path="talents"
                                element={
                                  <Recruiting.MyTalents></Recruiting.MyTalents>
                                }
                              ></Route>
                              <Route
                                path="skills"
                                element={
                                  <Recruiting.MyTechnologies></Recruiting.MyTechnologies>
                                }
                              ></Route>
                              <Route
                                path="tech-skills"
                                element={
                                  <Recruiting.MyTechSkills></Recruiting.MyTechSkills>
                                }
                              ></Route>
                            </Route>
                          </Route>
                        </Route>
                      )}
                      <Route path="analytics/*">
                        {checkPermission({
                          role: data?.role,
                          action: "route:analytics:preview",
                          section: data?.currentSection,
                        }) && (
                          <>
                            <Route
                              index
                              element={<Navigate to="bootcamp-performance" />}
                            />
                            <Route
                              path="bootcamp-performance"
                              element={<Analytics.BootcampPerformance />}
                            />
                            <Route
                              path="individual-performance"
                              element={<Analytics.IndividualPerformance />}
                            >
                              <Route
                                path=":id"
                                element={<Analytics.IndividualPerformance />}
                              ></Route>
                            </Route>
                          </>
                        )}

                        <Route path="*" element={<Page404 />} />
                      </Route>
                      {checkPermission({
                        role: data?.role,
                        action: "route:marketplace:preview",
                        section: data?.currentSection,
                      }) && (
                        <Route path="marketplace/*">
                          <Route index element={<Navigate to="list" />} />
                          <Route path="list" element={<Marketplace.List />} />
                          <Route path="*" element={<Page404 />} />
                        </Route>
                      )}

                      <Route path="*" element={<Page404 />} />
                    </Route>
                    <Route
                      path="/auth/accept"
                      element={
                        <RequireAuth>
                          <NotificationEmail></NotificationEmail>
                        </RequireAuth>
                      }
                    ></Route>
                    <Route
                      path="/link/:id"
                      element={
                        <RequireAuth>
                          <Resolve></Resolve>
                        </RequireAuth>
                      }
                    ></Route>
                    <Route
                      path="/invite"
                      element={
                        <RequireAuth>
                          <ByEmail></ByEmail>
                        </RequireAuth>
                      }
                    ></Route>
                    {/* en cohort,cursos,agregar, usar add-to-cohort y delete-from-cohort */}

                    <Route path="/" element={<AuthLayout />}>
                      <Route index element={<Navigate to="sign-in" />} />
                      <Route path="/sign-in" element={<SignIn />} />
                      <Route path="/sign-up" element={<SignUp />} />
                      <Route
                        path="/auth/confirm"
                        element={<ConfirmationAuth></ConfirmationAuth>}
                      ></Route>

                      {/* <Route aspath="/account-recovery" element={<SignIn />} /> */}
                      <Route path="*" element={<Page404 />} />
                      <Route
                        path="/terms"
                        element={<ConditionAndTerms></ConditionAndTerms>}
                      ></Route>
                    </Route>

                    <Route path="*" element={<Page404 />} />
                  </Routes>
                </DashboardProvider>
              </CurrentProvider>
            </GoogleOAuthProvider>
          </BrowserRouter>
        </ReactFlowProvider>
      </ThemeProvider>
    </AuthProvider>
  );
};

const states = ({ userStore, currentStore }) => {
  const { data: user } = userStore;
  const data = currentStore.data;
  return {
    user,
    data,
  };
};

export default connect(states)(Component);
