import React, { useCallback, useEffect, useRef, useState } from "react";
import { FlexContainer } from "../../../../components/StyledComponents";
import PublishRoadmapModal from "./components/PublishRoadmapModal";
import AddUsersModal from "./components/AddUsersModal";
import ShareModal from "./components/ShareModal";
import PrivateModal from "./components/PrivateModal";
import { connect } from "react-redux";
import ModalConfirmDelete from "../../../../components/ModalConfirmDelete";
import {
  initialValues,
  schema,
} from "../../../../constants/form/roadmap/roadmap-creator";
import Loader from "../../../../components/Loader";
import { Formik } from "formik";
import Grid from "../../../../components/Grid";
import SideBar from "./components/SideBar";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { BsFillTrash2Fill, BsFullscreen } from "react-icons/bs";
import { FaCloudUploadAlt, FaSave, FaShare } from "react-icons/fa";
import { BiLink } from "react-icons/bi";
import { HiUserAdd } from "react-icons/hi";
import { AiFillEdit, AiFillLock, AiFillUnlock } from "react-icons/ai";
import { showModal } from "../../../../store/actions/modal";
import { FiUpload } from "react-icons/fi";
import { toast } from "react-toastify";
import Input from "./components/Field";
import {
  deleteRoadmap,
  getRoadmap,
  newRoadmap,
  resetDeleteRoadmap,
  resetNewRoadmap,
  setGetRoadmap,
  updateRoadmap,
} from "../../../../store/actions/roadmap";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  Controls,
  Background,
  Node,
} from "reactflow";
import "reactflow/dist/style.css";
import EditNodeModal from "./components/EditNodeModal";
import { setFormData } from "../../../../helpers/formData";
import screenfull from "screenfull";
import {
  RoadmapUploadWrapper,
  RoadmapUploaderDescriptionWrapper,
  RoadmapUploaderTitle,
} from "./style";
import TextArea from "./components/TextArea";
import Submit from "../../../../components/Form/Submit";
import Button from "../../../../components/Button";
import { RiCloseCircleFill } from "react-icons/ri";
import DropzoneField from "../../../../components/Form/Dropzone";
import { RoadmapDto } from "../../../../types/roadmap.dto";
import { UserDto } from "../../../../types/user.dto";
import { StateDto } from "../../../../types/states.dto";
import { CurrentDto } from "../../../../types/current.dto";
import { CohortDto } from "../../../../types/cohort.dto";
import { getAllUnits, resetGetAllUnits } from "../../../../store/actions/unit";
import {
  getAllCohorts,
  resetGetAllCohortsData,
} from "../../../../store/actions/cohort";
const Component = ({
  roadmap,
  users,
  roadmapGetStates,
  roadmapDeleteStates,
  user,
  roadmapUpdateStates,
  roadmapNewStates,
  newRoadmapData,
  current,
  cohorts,
}: {
  roadmap: RoadmapDto;
  users: UserDto;
  roadmapGetStates: StateDto;
  roadmapDeleteStates: StateDto;
  user: UserDto;
  roadmapUpdateStates: StateDto;
  roadmapNewStates: StateDto;
  newRoadmapData?;
  current: CurrentDto;
  cohorts: CohortDto[];
}) => {
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const navigate = useNavigate();
  const params = useParams();
  const fitViewOptions = {
    padding: 3,
  };
  const reactFlowWrapper: any = useRef(null);
  const connectingNodeId: any = useRef(null);
  const roadmapWorkflowRef: any = useRef(null);
  const dispatch = useDispatch();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [roadmapDataChanged, setRoadmapDataChanged] = useState<{
    name: string;
    description: string;
    image?: any;
  }>({ name: "", description: "", image: undefined });
  const [isVisible, setIsVisible] = useState<boolean | null>(null);
  const [roadmapImage, setRoadmapImage] = useState<any>();
  const [isFullscreen, setIsFullScreen] = useState<boolean>(false);

  const [nodes, setNodes, onNodesChange] = useNodesState([
    {
      id: "1",
      data: { label: `Node 1` },
      position: { x: 0, y: 0 },
    },
  ]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedNode, setSelectedNode] = useState<Node | null>(null);
  const { project } = useReactFlow();
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  const generateNewUserId = (nodes) => {
    if (!(nodes.length > 0)) return `1`;
    const yes = Number(nodes[nodes.length - 1].id) + 1;
    return `${yes}`;
  };
  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);
  const onSelectImage = (files) => {
    if (files.length <= 0) {
      setRoadmapImage(undefined);

      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setRoadmapImage({
      file: files[0],
      preview: URL.createObjectURL(files[0]),
    });
  };
  const onConnectEnd = useCallback(
    (event) => {
      const targetIsPane = event.target.classList.contains("react-flow__pane");

      if (targetIsPane) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
        const id = generateNewUserId(nodes);
        const newNode = {
          id,
          // we are removing the half of the node width (75) to center the new node
          position: project({
            x: event.clientX - left - 75,
            y: event.clientY - top,
          }),
          data: { label: `Node ${id}` },
          urls: [],
          style: { fontSize: "18px" },
        };

        setNodes((nds) => nds.concat(newNode));
        setEdges((eds: any) =>
          eds.concat({ id, source: connectingNodeId.current, target: id })
        );
      }
    },
    [project, nodes]
  );
  const onSubmit = (values, actions) => {
    if (!!roadmap) {
      dispatch(
        updateRoadmap({
          _id: roadmap?._id,
          body: setFormData(
            {
              name: roadmapDataChanged.name,
              description: roadmapDataChanged.description,
              ...(!!roadmapImage && { picture: roadmapImage.file }),
              graph: JSON.stringify({
                nodes: nodes,
                edges: edges,
              }),
              published: true,
              available: true,
              allCohorts: true,
              __v: roadmap.__v,
            },
            ["picture"]
          ),
        })
      );
    } else {
      dispatch(
        newRoadmap(
          setFormData(
            {
              name: roadmapDataChanged.name,
              description: roadmapDataChanged.description,
              published: true,
              type: "ROADMAP",
              available: true,
              allCohorts: true,
              graph: JSON.stringify({
                nodes: nodes,
                edges: edges,
              }),
              picture: roadmapImage.file,
              academy: current.id,
            },
            ["picture"]
          )
        )
      );
    }
  };

  const menu = [
    {
      roadmapRole: ["OWNER"],
      Icon: FiUpload,
      toolTipName: !!roadmap?.published ? "Editar Roadmap" : "Publicar Roadmap",
      onClick: () => {
        dispatch(showModal("publish-roadmap-modal"));
      },
      IconActive: AiFillEdit,
      activeFunction: () => !!roadmap?.published,
      onClickActive: () => {
        dispatch(showModal("publish-roadmap-modal"));
      },
    },
    {
      Icon: AiFillLock,
      roadmapRole: ["OWNER"],
      IconActive: AiFillUnlock,
      toolTipName: "Cambiar visibilidad",
      onAvailable: () => !!roadmap && !!roadmap?.published,
      activeFunction: () => !!roadmap?.published,
      onClick: () => {
        dispatch(showModal("private-roadmap"));
      },
      onClickActive: () => {
        dispatch(showModal("private-roadmap"));
      },
    },
    {
      Icon: HiUserAdd,
      roadmapRole: ["OWNER", "COOWNER"],
      toolTipName: "Añadir Usuarios",
      onAvailable: () => !!roadmap && !!roadmap?.published,
      onClick: () => {
        dispatch(showModal("add-user-roadmap"));
      },
    },
    {
      Icon: FaSave,
      toolTipName: "Guardar",
      roadmapRole: ["OWNER", "COOWNER"],
      onClick: () => {},
      activeFunction: () =>
        (!roadmap &&
          roadmapDataChanged.name?.length > 5 &&
          roadmapDataChanged.description?.length > 0 &&
          !!roadmapImage &&
          nodes) ||
        (!!roadmap &&
          roadmapDataChanged.name?.length > 5 &&
          roadmapDataChanged.description?.length > 0 &&
          (roadmap?.name !== roadmapDataChanged.name ||
            roadmap?.description !== roadmapDataChanged.description ||
            (nodes.length > 0 && roadmap?.graph) ||
            (!!roadmapImage && roadmap?.picture))),
      onAvailable: () =>
        (!roadmap &&
          roadmapDataChanged.name?.length > 5 &&
          roadmapDataChanged.description?.length > 0 &&
          !!roadmapImage &&
          nodes.length > 0) ||
        (!!roadmap &&
          roadmapDataChanged.name?.length > 5 &&
          roadmapDataChanged.description?.length > 0 &&
          (roadmap?.name !== roadmapDataChanged.name ||
            roadmap?.description !== roadmapDataChanged.description ||
            (nodes.length > 0 && roadmap?.graph) ||
            (!!roadmapImage && roadmap?.picture))),
      onClickActive: () => {
        if (!!roadmap) {
          dispatch(
            updateRoadmap({
              _id: roadmap?._id,
              body: setFormData(
                {
                  name: roadmapDataChanged.name,
                  description: roadmapDataChanged.description,

                  ...(!!roadmapImage && { picture: roadmapImage.file }),
                  graph: JSON.stringify({
                    nodes: nodes,
                    edges: edges,
                  }),

                  __v: roadmap.__v,
                },
                ["picture"]
              ),
            })
          );
        } else {
          dispatch(
            newRoadmap(
              setFormData(
                {
                  name: roadmapDataChanged.name,
                  description: roadmapDataChanged.description,
                  type: "ROADMAP",
                  picture: roadmapImage.file,
                  academy: current.id,
                  graph: JSON.stringify({
                    nodes: nodes,
                    edges: edges,
                  }),
                },
                ["picture"]
              )
            )
          );
        }
      },
    },
    {
      Icon: BiLink,
      roadmapRole: ["OWNER", "COOWNER", "READER"],
      toolTipName: "Copiar Link",
      onAvailable: () =>
        !!roadmap && !!roadmap?.available && !!roadmap?.published,
      onClick: () => {
        navigator.clipboard.writeText(
          `https://academia.batan.coop/dashboard/roadmaps/${roadmap?._id}`
        );
        toast.success(`Link copiado`);
      },
    },
    {
      Icon: FaShare,
      toolTipName: "Compartir",
      roadmapRole: ["OWNER", "COOWNER", "READER"],
      onAvailable: () =>
        !!roadmap && !!roadmap?.available && !!roadmap?.published,
      onClick: () => {
        dispatch(showModal("share-user-roadmap"));
      },
    },
    {
      Icon: BsFillTrash2Fill,
      roadmapRole: ["OWNER"],
      toolTipName: "Eliminar Video",
      onAvailable: () => !!roadmap,
      onClick: () => {
        dispatch(showModal("confirm-delete-item-secuencial-modal"));
      },
    },
  ];

  useEffect(() => {
    if (!!params.id) {
      dispatch(getRoadmap({ _id: params.id }));
    }
  }, [params]);

  useEffect(() => {
    !cohorts &&
      fetchOn &&
      dispatch(
        getAllCohorts({ filterBy: { academy: current.id, active: true } })
      );
  }, [cohorts, fetchOn]);

  useEffect(() => {
    if (cohorts && fetchOn) {
      dispatch(
        getAllUnits({
          filterBy: { cohort: { $in: cohorts.map((cohort) => cohort._id) } },
        })
      );
    }
  }, [cohorts]);
  useEffect(() => {
    if (!cohorts) {
      setFetchOn(true);
    }
  }, [cohorts]);

  useEffect(() => {
    if (!params.id && !roadmap) {
      setNodes([
        {
          id: "1",
          data: { label: `Node 1` },
          position: { x: 0, y: 0 },
        },
      ]);
    }
    if (!params.id && !!roadmap) {
      dispatch(setGetRoadmap(null));
    }
    if (!!params.id && !!roadmap) {
      setNodes(roadmap.graph.nodes);
      setEdges(roadmap.graph.edges);
      setRoadmapDataChanged({
        name: roadmap.name,
        description: roadmap.description,
        ...(!!roadmap.picture && { image: roadmap.picture }),
      });
    }
  }, [roadmap, params]);

  useEffect(() => {
    if (roadmapNewStates.success) {
      toast.success(`Se creo el Roadmap correctamente`);
      if (newRoadmapData) {
        navigate(newRoadmapData._id);
      }
      dispatch(resetNewRoadmap());
    }
    if (roadmapNewStates.error) {
      toast.error(`Error al crear el roadmap`);
      dispatch(resetNewRoadmap());
    }
  }, [roadmapNewStates]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCohortsData());
      dispatch(resetGetAllUnits());
    };
  }, []);

  return (
    <>
      {(!!params.id && !roadmap) || roadmapGetStates.loading ? (
        <Loader color="Primary"></Loader>
      ) : (
        <>
          <Grid.Container>
            <Formik
              initialValues={initialValues({
                name: roadmapDataChanged.name,
                description: roadmapDataChanged.description,
              })}
              onSubmit={(values, actions) => {
                onSubmit(values, actions);
              }}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={schema}
              validateOnMount={false}
              enableReinitialize
            >
              {({
                touched,
                errors,
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                resetForm,
                setFieldValue,
                isSubmitting,
              }) => {
                return (
                  <form
                    className="theme-form"
                    onSubmit={(event) => {
                      setFormSubmmited(true);
                      handleSubmit(event);
                    }}
                    id="roadmap-player-create"
                  >
                    <FlexContainer
                      style={{
                        backgroundColor: isFullscreen
                          ? "rgb(245, 245, 245)"
                          : "",
                      }}
                      ref={roadmapWorkflowRef}
                      gap="10px"
                    >
                      <div style={{ width: "calc(100% - 50px)" }}>
                        <FlexContainer direction="column" gap="10px">
                          <div
                            style={{
                              height: "600px",
                              width: "100%",
                              background: "#fff",
                              position: "relative",
                            }}
                            ref={reactFlowWrapper}
                          >
                            <BsFullscreen
                              size={20}
                              onClick={() => {
                                if (
                                  roadmapWorkflowRef.current &&
                                  screenfull.isEnabled
                                ) {
                                  if (screenfull.isFullscreen) {
                                    setIsFullScreen(false);
                                    screenfull.exit();
                                  } else {
                                    setIsFullScreen(true);
                                    screenfull.request(
                                      roadmapWorkflowRef.current
                                    );
                                  }
                                }
                              }}
                              style={{
                                position: "absolute",
                                left: "10px",
                                top: "10px",
                                zIndex: 20,
                                cursor: "pointer",
                              }}
                            ></BsFullscreen>
                            {selectedNode && (
                              <EditNodeModal
                                setNodes={setNodes}
                                setIsVisible={setIsVisible}
                                isVisible={isVisible}
                                node={selectedNode}
                                setSelectedNode={setSelectedNode}
                              ></EditNodeModal>
                            )}
                            <ReactFlow
                              nodes={nodes}
                              edges={edges}
                              onNodesChange={onNodesChange}
                              onEdgesChange={onEdgesChange}
                              onConnect={onConnect}
                              onConnectStart={onConnectStart}
                              onConnectEnd={onConnectEnd}
                              fitView
                              fitViewOptions={fitViewOptions}
                              onNodeClick={(ev, node) => {
                                setIsVisible(true);
                                setSelectedNode(node);
                              }}
                            >
                              <Controls></Controls>
                              <Background color="#1573e5"></Background>
                            </ReactFlow>
                          </div>
                          {!isFullscreen && (
                            <RoadmapUploaderDescriptionWrapper>
                              <FlexContainer
                                justify="space-between"
                                wrap="wrap"
                                gap="10px"
                              >
                                <FlexContainer direction="column" gap="12px">
                                  <FlexContainer
                                    direction="column"
                                    gap="12px"
                                    style={{ width: "360px" }}
                                  >
                                    <Input
                                      containerStyles={{
                                        width: "100%",
                                        maxWidth: "340px",
                                        borderColor: "#697482",
                                      }}
                                      name="name"
                                      touched={touched["name"]}
                                      value={values["name"]}
                                      error={errors["name"]}
                                      type="text"
                                      placeholder="Nombre del Roadmap"
                                      onChange={(ev) => {
                                        setRoadmapDataChanged((state) => {
                                          return {
                                            ...state,
                                            name: ev.target.value,
                                          };
                                        });
                                        handleChange(ev);
                                      }}
                                      onBlur={handleBlur}
                                      options={{
                                        marginBottom: 10,
                                      }}
                                    />
                                    <TextArea
                                      containerStyles={{
                                        width: "100%",
                                        maxWidth: "340px",
                                      }}
                                      name="description"
                                      error={errors["description"]}
                                      touched={touched["description"]}
                                      value={values["description"]}
                                      onChange={(ev) => {
                                        setRoadmapDataChanged((state) => {
                                          return {
                                            ...state,
                                            description: ev.target.value,
                                          };
                                        });
                                        handleChange(ev);
                                      }}
                                      placeholder="Escriba su descripcion"
                                      onBlur={handleBlur}
                                      options={{
                                        skin: "base",
                                        marginBottom: 10,
                                      }}
                                    />
                                    <FlexContainer
                                      gap="10px"
                                      align="center"
                                      wrap="wrap"
                                    >
                                      {((!!roadmap &&
                                        (roadmap?.users.find(
                                          (userFounded) =>
                                            !userFounded.roleEnd &&
                                            userFounded.id === user?._id
                                        )?.role === "OWNER" ||
                                          roadmap?.users.find(
                                            (userFounded) =>
                                              !userFounded.roleEnd &&
                                              userFounded.id === user?._id
                                          )?.role === "COOWNER")) ||
                                        !roadmap) && (
                                        <>
                                          <Submit
                                            isSubmmiting={
                                              roadmapUpdateStates.loading
                                            }
                                            form="roadmap-player-create"
                                            color="Primary"
                                            options={{
                                              type: "filled",
                                              skin: "primary",
                                              size: "lg",
                                            }}
                                          >
                                            Publicar Rapido
                                          </Submit>

                                          <Button
                                            onClick={() => {
                                              dispatch(
                                                showModal(
                                                  "publish-roadmap-modal"
                                                )
                                              );
                                            }}
                                            type="button"
                                            options={{
                                              type: "filled",
                                              skin: "primary",
                                              size: "lg",
                                            }}
                                            style={{ marginLeft: "10px" }}
                                          >
                                            Publicar
                                          </Button>
                                        </>
                                      )}
                                    </FlexContainer>
                                  </FlexContainer>
                                </FlexContainer>
                                {!roadmapImage && !roadmapDataChanged.image ? (
                                  <DropzoneField
                                    name="videoImg"
                                    onChange={(files) => {
                                      onSelectImage(files);
                                    }}
                                    onBlur={handleBlur}
                                    error={
                                      !roadmapImage &&
                                      !roadmapDataChanged.image &&
                                      "Se debe subir una imagen"
                                    }
                                    touched={touched["videoImg"]}
                                    placeholder={
                                      "Selecciona el archivo que quieras subir"
                                    }
                                    options={{
                                      size: "small",
                                      openOnClick: true,
                                    }}
                                    validation={{
                                      maxFileSize: 1024,
                                      maxFiles: 1,
                                      accept: "image/*",
                                    }}
                                  />
                                ) : (
                                  <RoadmapUploadWrapper
                                    height={"200px"}
                                    style={{
                                      width: "340px",
                                      ...(!!roadmapImage && {
                                        backgroundImage: `url(${roadmapImage.preview})`,
                                      }),
                                      ...(!!roadmapDataChanged.image && {
                                        backgroundImage: `url(${roadmapDataChanged.image})`,
                                      }),
                                      backgroundSize: "cover",
                                      backgroundRepeat: "no-repeat",
                                      backgroundPosition: "center center",
                                    }}
                                  >
                                    <RiCloseCircleFill
                                      onClick={() => {
                                        setRoadmapImage(undefined);
                                        setRoadmapDataChanged((state) => {
                                          return {
                                            ...state,
                                            image: undefined,
                                          };
                                        });
                                      }}
                                      size={25}
                                      fill={"#E91E63"}
                                      color={"#fff"}
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "10px",
                                      }}
                                    ></RiCloseCircleFill>
                                  </RoadmapUploadWrapper>
                                )}
                              </FlexContainer>
                            </RoadmapUploaderDescriptionWrapper>
                          )}
                        </FlexContainer>
                      </div>
                      <SideBar roadmap={roadmap} menu={menu}></SideBar>
                    </FlexContainer>
                  </form>
                );
              }}
            </Formik>
          </Grid.Container>

          <PublishRoadmapModal
            roadmapImage={roadmapImage}
            roadmapDataChanged={roadmapDataChanged}
            nodes={nodes}
            edges={edges}
            roadmap={roadmap}
          ></PublishRoadmapModal>

          {!!roadmap && <AddUsersModal roadmap={roadmap}></AddUsersModal>}
          {!!roadmap && <ShareModal roadmap={roadmap}></ShareModal>}
          {!!roadmap && (
            <PrivateModal
              roadmap={{
                _id: roadmap?._id,
                published: roadmap?.published,
                name: roadmapDataChanged.name,
                description: roadmapDataChanged.description,
                __v: roadmap?.__v,
              }}
            ></PrivateModal>
          )}
          {!!roadmap && (
            <ModalConfirmDelete
              bntConfig={{
                content: "Eliminar",
                style: {
                  style: { width: "170px", height: "40px" },
                  color: "Danger",
                  options: {
                    type: "filled",
                    skin: "danger",
                    size: "lg",
                    marginBottom: "0px",
                  },
                },
              }}
              sucessAction={() => navigate("/dashboard/contents/roadmaps")}
              states={roadmapDeleteStates}
              description={`El Roadmap se eliminara para siempre del sistema.`}
              title={
                <span
                  style={{
                    color: "#697482",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  {`¿Seguro que quiere eliminar a ${roadmap?.name}?`}
                </span>
              }
              elementActions={deleteRoadmap({ _id: roadmap?._id })}
              resetAction={resetDeleteRoadmap}
              resetState={() => {}}
            ></ModalConfirmDelete>
          )}
        </>
      )}
    </>
  );
};

const states = ({
  usersStore,
  roadmapStore,
  userStore,
  currentStore,
  cohortStore,
}) => {
  const { data: users } = usersStore.all;
  const { data: roadmap, states: roadmapGetStates } = roadmapStore.roadmap;
  const { states: roadmapDeleteStates } = roadmapStore.delete;
  const { data: user } = userStore;
  const { states: roadmapUpdateStates } = roadmapStore.update;
  const { states: roadmapNewStates, data: newRoadmapData } = roadmapStore.new;
  const { data: current } = currentStore;
  const { data: cohorts } = cohortStore.allCohorts;
  return {
    users,
    roadmap,
    roadmapGetStates,
    roadmapDeleteStates,
    user,
    roadmapUpdateStates,
    roadmapNewStates,
    newRoadmapData,
    current,
    cohorts,
  };
};

export default connect(states)(Component);
