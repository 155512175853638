import {
  Navbar,
  Nav,
  NavItems,
  NavItem,
  NavLink,
  ButtonLink,
  DropdownMenu,
  DropdownItem,
  SubNavLink,
  Caret,
  DropdownMenuChild,
} from "./styles";
import CalendarIcon from "../../assets/icons/menu/calendar.svg";
import StudentIcon from "../../assets/icons/menu/student-graduation-cap.svg";
import marketplace from "../../assets/icons/menu/marketplace.svg";
import analytics from "../../assets/icons/menu/analytics.svg";
import Gestion from "../../assets/icons/menu/gestionar.png";
import money from "../../assets/icons/menu/bank.svg";
import team from "../../assets/icons/menu/team-icon.svg";
import cohort from "../../assets/icons/menu/cohorts.png";
import education from "../../assets/icons/menu/education.svg";
import dashboardIcon from "../../assets/icons/menu/gestion-icon.svg";
import integrations from "../../assets/icons/menu/integrations-con.svg";
import notificaciones from "../../assets/icons/menu/notification.png";
import templates from "../../assets/icons/menu/template.png";
import settings from "../../assets/icons/menu/settings.svg";
import Home from "../../assets/icons/menu/home.png";
import members from "../../assets/icons/menu/members.svg";
import table from "../../assets/icons/menu/tablero.svg";
import content from "../../assets/icons/menu/icon-document.svg";
import { TeamDto } from "../../types/team.dto";
import Icon from "../Icon";
import CaretDown from "../../assets/icons/menu/caret-down.svg";
import { connect, useDispatch } from "react-redux";
import { UserDto } from "../../types/user.dto";
import { useId, useState } from "react";
import { CurrentDto } from "../../types/current.dto";
import { checkPermission } from "../PermissionsChecker";
import { v4 as uuid } from "uuid";
import teamActions from "../../store/actions/team";
import debitcard from "../../assets/icons/menu/signal-alt-2.svg";
import { useWindowSize } from "../../hooks/useWindowScreen";
interface ComponentProps {
  user: UserDto;
  sidebarCollapsed: boolean;
  data: CurrentDto;
  teams: TeamDto[];
}

const Component = ({ user, sidebarCollapsed, data, teams }: ComponentProps) => {
  const [activeDropdown, setActiveDropdown] = useState<string>();
  const { isMobile } = useWindowSize();
  const [activeDropdownChildren, setActiveDropdownChildren] =
    useState<string>();
  const [id, setId] = useState<string>();
  const [firstOpen, setFirstOpen] = useState<string>();
  const dispatch = useDispatch();

  const makeSubMenu = (
    firstItem,
    childrens,
    isActiveDropdown,
    mainLink,
    activeDropdown,
    setIdLink,
    idLink
  ) => {
    if (!childrens) return null;
    const childrensChecked = childrens.filter((children) =>
      checkPermission({
        role: data.role,
        action: children.actions,
        section: data.currentSection,
      })
    );
    return childrens.map((children) => {
      if (
        !checkPermission({
          role: data.role,
          action: children.actions,
          section: data.currentSection,
        })
      ) {
        return null;
      }

      return !!children.children ? (
        <NavItem className={sidebarCollapsed ? "sidebar-collapsed" : ""}>
          <ButtonLink
            onClick={(event) => {
              setIdLink(idLink);
              setActiveDropdown(
                activeDropdown === `${mainLink}${children.link}`
                  ? mainLink
                  : `${mainLink}${children.link}`
              );
            }}
            className={
              `${mainLink}${children.link}` === activeDropdown ? "active" : ""
            }
          >
            <div>
              <span>{children.title}</span>
            </div>
            <Caret className="caret">
              <Icon icon={CaretDown} color="#697482" size="18px" />
            </Caret>
          </ButtonLink>
          <DropdownMenuChild
            amountItems={
              children.children.filter((children) =>
                checkPermission({
                  role: data.role,
                  action: children.actions,
                  section: data.currentSection,
                })
              ).length
            }
            isActiveDropdown={activeDropdown === `${mainLink}${children.link}`}
          >
            {makeSubMenu(
              firstItem,
              children.children,
              isActiveDropdown,
              `${mainLink}${children.link}`,
              activeDropdown,
              setId,
              idLink
            )}
          </DropdownMenuChild>
        </NavItem>
      ) : (
        <DropdownItem>
          <SubNavLink
            className={({ isActive }) => (isActive ? "active" : undefined)}
            onClick={() => {
              setIdLink(idLink);
              if (!!children.action) {
                dispatch(children.action());
              }
            }}
            to={`/dashboard${mainLink}${children.link}`}
          >
            {children.title}
          </SubNavLink>
        </DropdownItem>
      );
    });
  };

  const menuMobile = {
    academy: [
      {
        link: "/contents",
        title: "Contenidos",
        icon: content,
        actions: "route:contents:preview",
        children: [
          {
            link: "/video",
            title: "Videos",
            actions: "route:contents:videos:preview",
          },
          {
            link: "/cdocs",
            title: "Cdocs",
            actions: "route:contents:cdocs:preview",
          },
          {
            link: "/bytes",
            title: "Bytes",
            actions: "route:contents:videos:preview",
          },
          {
            link: "/roadmaps",
            title: "Trayectos",
            actions: "route:contents:roadmaps:preview",
          },
          {
            link: "/slideshows",
            title: "SlideShows",
            actions: "route:contents:slideshows:preview",
          },
          {
            link: "/listens",
            title: "Listens",
            actions: "route:contents:listens:preview",
          },
          {
            link: "/images",
            title: "Imagenes",
            actions: "route:contents:images:preview",
          },
          {
            link: "/variety",
            title: "Contenidos Varios",
            actions: "route:contents:variety:preview",
          },
        ],
      },
    ],
    personal: [
      {
        link: "/home",
        title: "Home",
        icon: Home,
        actions: "route:home:preview",
      },
    ],
  };

  const menu = {
    organization: [
      {
        link: "/marketplace",
        title: "Marketplace",
        icon: marketplace,
        actions: "route:marketplace:preview",
      },
      {
        link: "/team",
        title: "Equipos",
        icon: team,
        actions: "route:team:preview",
        children: teams
          ?.filter(
            (team) =>
              team.organization === data.id && team.users.includes(user._id)
          )
          .filter((team) => team.active)
          .map((team) => {
            return {
              link: `/teams/${team._id}`,
              title: team.name,
              action: "route:team:ait:preview",
              children: [
                {
                  link: `/ait`,
                  title: "Tablero",
                  action: () => teamActions.selectOne(team._id),
                },
                {
                  link: `/companies`,
                  title: "Compañias",
                  action: () => teamActions.selectOne(team._id),
                },
                {
                  link: `/searches`,
                  title: "Busquedas",
                  action: () => teamActions.selectOne(team._id),
                },
                {
                  link: `/talents`,
                  title: "Talentos",
                  action: () => teamActions.selectOne(team._id),
                },
                {
                  link: `/skills`,
                  title: "Skills",
                  action: () => teamActions.selectOne(team._id),
                },
                {
                  link: `/tech-skills`,
                  title: "Skills Tecnicos",
                  action: () => teamActions.selectOne(team._id),
                },
              ],
            };
          }),
      },

      {
        link: "/calendar",
        title: "Calendario",
        icon: CalendarIcon,
        actions: "route:calendar:preview",
      },
      {
        link: "/dashboard",
        title: "Dashboard",
        actions: "route:dashboard:preview",
        icon: dashboardIcon,
        children: [
          {
            link: "/organization/general",
            title: "General",
            actions: "route:dashboard:preview:organization:general",
          },
          {
            link: "/organization/members",
            title: "Miembros",
            actions: "route:dashboard:preview:organization:members",
          },
          {
            link: "/organization/talents",
            title: "Talentos",
            actions: "route:dashboard:preview:organization:talents",
          },
        ],
      },
      {
        link: "/management",
        title: "Gestionar",
        icon: Gestion,
        actions: "route:management:preview",
        children: [
          {
            link: "/profile",
            title: "Perfil",
            actions: "route:management:profile:preview",
          },
          {
            link: "/members",
            title: "Miembros",
            actions: "route:management:members:preview",
          },
          {
            link: "/activities",
            title: "Actividades",
            actions: "route:management:activities:preview",
          },
          {
            link: "/analytics",
            title: "Analisis",
            actions: "route:management:analytics:preview",
          },
          {
            link: "/customize",
            title: "Customizar",
            actions: "route:management:customize:preview",
          },
        ],
      },

      {
        link: "/notification",
        title: "Notificaciones",
        icon: notificaciones,
        actions: "route:notification:preview",
      },
      /* {
        link: "/integrations",
        title: "Integraciones",
        icon: integrations,
        actions: "route:integrations:preview",
        children: [
          {
            link: "/myapps",
            title: "Mis Apps",
          },
          {
            link: "/create",
            title: "Crear Apps",
          },
        ],
      }, */
      /*      {
        link: "/templates",
        title: "Templates",
        icon: templates,
        actions: "route:template:preview",
      },
      {
        link: "/settings",
        title: "Configuracion",
        icon: settings,
        actions: "route:config:preview",
      }, */
    ],
    academy: [
      {
        link: "/contents",
        title: "Contenidos",
        icon: content,
        actions: "route:contents:preview",
        children: [
          {
            link: "/video",
            title: "Videos",
            actions: "route:contents:videos:preview",
          },
          {
            link: "/cdocs",
            title: "Cdocs",
            actions: "route:contents:cdocs:preview",
          },
          {
            link: "/bytes",
            title: "Bytes",
            actions: "route:contents:videos:preview",
          },
          {
            link: "/roadmaps",
            title: "Trayectos",
            actions: "route:contents:roadmaps:preview",
          },
          {
            link: "/slideshows",
            title: "SlideShows",
            actions: "route:contents:slideshows:preview",
          },
          {
            link: "/listens",
            title: "Listens",
            actions: "route:contents:listens:preview",
          },
          {
            link: "/images",
            title: "Imagenes",
            actions: "route:contents:images:preview",
          },
          {
            link: "/variety",
            title: "Contenidos Varios",
            actions: "route:contents:variety:preview",
          },
        ],
      },
      {
        link: "/cohorts",
        title: "Co-horts",
        icon: cohort,
        actions: "route:cohort:preview",
        children: [
          {
            link: "/list",
            title: "Listado",
            actions: "route:cohort:list:preview",
          },
          {
            link: "/events",
            title: "Eventos Sincrónicos",
            actions: "route:cohort:events:preview",
          },
          {
            link: "/customize",
            title: "Customizar módulos",
            actions: "route:cohort:customize:preview",
          },
        ],
      },
      {
        link: "/academy",
        title: "Academia",
        icon: cohort,
        actions: "route:academy:preview",
        children: [
          {
            link: "/profile",
            title: "Perfil",
            actions: "route:academy:profile:preview",
          },
          {
            link: "/gestion",
            title: "Gestion",
            actions: "route:academy:gestion:preview",
          },
        ],
      },
      {
        link: "/calificates",
        title: "Calificaciones",
        icon: education,
        actions: "route:calificate:preview",
        children: [
          {
            link: "/evaluate",
            title: "Calificar",
            actions: "route:calificate:evaluate:preview",
          },
          {
            link: "/criterions",
            title: "Criterios",
            actions: "route:calificate:criterions:preview",
          },
          {
            link: "/subcriterions",
            title: "Subcriterios",
            actions: "route:calificate:subcriterion:preview",
          },
        ],
      },

      {
        link: "/management",
        title: "Gestionar",
        icon: Gestion,
        actions: "route:management:preview",
        children: [
          {
            link: "/profile",
            title: "Perfil",
            actions: "route:management:profile:preview",
          },
          {
            link: "/collaborators",
            title: "Colaboradores",
            actions: "route:management:collaborators:preview",
            children: [
              {
                link: "/management",
                title: "Gestion",
                actions: "route:management:collaborators:management:preview",
              },
            ],
          },
          {
            link: "/certificates",
            title: "Certificados",
            actions: "route:management:certificate:preview",
            children: [
              {
                link: "/management",
                title: "Gestion",
                actions: "route:management:certificate:management:preview",
              },
            ],
          },
          {
            link: "/subscribers",
            title: "Suscriptores",
            actions: "route:management:suscribers:preview",
            children: [
              {
                link: "/list",
                title: "Listado",
                actions: "route:management:suscribers:list:preview",
              },
            ],
          },
          {
            link: "/marketplace",
            title: "Mi Marketplace",
            actions: "route:management:marketplace:preview",
          },
          {
            link: "/calendar",
            title: "Calendario",
            actions: "route:management:calendar:preview",
          },
        ],
      },
      {
            link: "/dashboard/academy/gestion",
            title: "Gestión",
            icon: dashboardIcon,
            //icon: GestionIcon,  // Añade un ícono si lo tienes
            actions: "route:dashboard:gestion:preview",  // Acción asociada
          },
      {
        link: "/dashboard",
        title: "Dashboard",
        icon: dashboardIcon,
        actions: "route:dashboard:preview",
        children: [
          {
            link: "/academia",
            title: "Academia",
            actions: "route:dashboard:academy:preview",     
          },
          {
            link: "/constana",
            title: "Constana",
            actions: "route:dashboard:constana:preview",
            children: [
              {
                link: "/general",
                title: "General",
                actions: "route:dashboard:constana:general:preview",
              },
              {
                link: "/jobs",
                title: "Insercion laboral",
                actions: "route:dashboard:constana:jobs:preview",
              },
            ],
          },
          {
            link: "/campus",
            title: "Campus",
            actions: "route:dashboard:campus:preview",
            children: [
              {
                link: "/general",
                title: "General",
                actions: "route:dashboard:campus:general:preview",
              },
              {
                link: "/audience",
                title: "Audiencia",
                actions: "route:dashboard:campus:audience:preview",
              },
            ],
          },
          {
            link: "/contents",
            title: "Contenido",
            actions: "route:dashboard:contents:preview",
            children: [
              {
                link: "/general",
                title: "General",
                actions: "route:dashboard:contents:general:preview",
              },
              {
                link: "/cohorts",
                title: "Cohorts",
                actions: "route:dashboard:contents:cohorts:preview",
              },
            ],
          },
          {
            link: "/cohorts",
            title: "Cohorts",
            actions: "route:dashboard:cohorts:preview",
            children: [
              {
                link: "/cohort",
                title: "Grupal",
                actions: "route:dashboard:cohorts:cohort:preview",
              },
              {
                link: "/individual",
                title: "Individual",
                actions: "route:dashboard:cohorts:individual:preview",
              },
            ],
          },
          {
            link: "/monetization",
            title: "Monetizacion",
            actions: "route:dashboard:earning:preview",
            children: [
              {
                link: "/earning",
                title: "Ingresos",
                actions: "route:dashboard:earning:academy:preview",
              },
              {
                link: "/kpis",
                title: "KPIs",
                actions: "route:dashboard:earning:kpis:preview",
              },
            ],
          },
        ],
      },
      {
        link: "/monetization",
        title: "Monetizacion",
        actions: "route:monetization:preview",
        icon: debitcard,
        /* children: [
          {
            link: "/academy",
            title: "Acceso a Academia",
            actions: "route:monetization:academy:preview",
          },
          {
            link: "/cohort",
            title: "Acceso a Cohort",
            actions: "route:monetization:cohort:preview",
          },
          {
            link: "/myfinance",
            title: "Mi finanzas",
            actions: "route:myfinances:preview",
          },
        ], */
      },
      {
        link: "/myfinances",
        title: "Mis Finanzas",
        actions: "route:myfinances:preview",
        icon: money,
      },
      {
        link: "/notification",
        title: "Notificaciones",
        icon: notificaciones,
        actions: "route:notification:preview",
      },
    ],
    personal: [
      {
        link: "/home",
        title: "Home",
        icon: Home,
        actions: "route:home:preview",
      },
      {
        link: "/profile",
        title: "Perfil",
        icon: StudentIcon,
        actions: "route:profile:preview",
      },
      /* {
        link: "/table",
        title: "Mi Tablero",
        icon: table,
        actions: "route:table:preview",
      }, */
      {
        link: "/calendar",
        title: "Mi Calendario",
        icon: CalendarIcon,
        actions: "route:calendar:preview",
      },

      {
        link: "/dashboard",
        title: "Dashboard",
        icon: dashboardIcon,
        actions: "route:dashboard:preview",
        children: [
          {
            link: "/cohorts",
            title: "Cohorts",
            actions: "route:dashboard:cohorts:preview",
            children: [
              {
                link: "/cohort",
                title: "Grupal",
                actions: "route:dashboard:cohorts:cohort:preview",
              },
              {
                link: "/individual",
                title: "Individual",
                actions: "route:dashboard:cohorts:individual:preview",
              },
            ],
          },
        ],
      },
      {
        link: "/marketplace",
        title: "Marketplace",
        icon: marketplace,
        actions: "route:marketplace:preview",
      },

      {
        link: "/notification",
        title: "Notificaciones",
        icon: notificaciones,
        actions: "route:notification:preview",
      },
    ],
  };
  return (
    <Navbar>
      <Nav className={sidebarCollapsed ? "sidebar-collapsed" : ""}>
        <NavItems>
          <>
            {(isMobile ? menuMobile : menu)[
              !!data.currentSection ? data.currentSection : "personal"
            ].map((menuItem) => {
              const idLink = uuid();

              const isActiveDropdown = firstOpen === menuItem.link;

              if (
                !checkPermission({
                  role: data.role,
                  action: menuItem.actions,
                  section: data.currentSection,
                })
              ) {
                return null;
              }
              return (
                <NavItem
                  className={sidebarCollapsed ? "sidebar-collapsed" : ""}
                >
                  {menuItem.children ? (
                    <>
                      <ButtonLink
                        onClick={(event) => {
                          setFirstOpen(isActiveDropdown ? "" : menuItem.link);
                          setActiveDropdown(
                            isActiveDropdown ? "" : menuItem.link
                          );
                        }}
                        className={isActiveDropdown ? "active" : ""}
                        sidebarCollapsed={sidebarCollapsed}
                      >
                        <div>
                          {!!menuItem.icon && (
                            <Icon
                              icon={menuItem.icon}
                              color="#697482"
                              size="17px"
                            />
                          )}

                          <span>{menuItem.title}</span>
                        </div>

                        <Caret className="caret">
                          <Icon icon={CaretDown} color="#697482" size="18px" />
                        </Caret>
                      </ButtonLink>
                      <DropdownMenu
                        amountItems={
                          menuItem.children.filter((children) =>
                            checkPermission({
                              role: data.role,
                              action: children.actions,
                              section: data.currentSection,
                            })
                          ).length
                        }
                        isActiveDropdown={isActiveDropdown}
                        subChilds={
                          activeDropdown?.includes(menuItem.link) &&
                          activeDropdown?.length > menuItem.link.length
                        }
                      >
                        {makeSubMenu(
                          firstOpen,
                          menuItem.children,
                          isActiveDropdown,
                          menuItem.link,
                          activeDropdown,
                          setId,
                          idLink
                        )}
                      </DropdownMenu>
                    </>
                  ) : (
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "active" : undefined
                      }
                      sidebarCollapsed={sidebarCollapsed}
                      to={`/dashboard${menuItem.link}`}
                      onClick={() => {
                        if (!!menuItem.action) {
                          menuItem.action();
                        }
                        setActiveDropdown("");
                        setActiveDropdownChildren(menuItem.link);
                      }}
                    >
                      <div>
                        {!!menuItem.icon && (
                          <Icon
                            icon={menuItem.icon}
                            color="#697482"
                            size="17px"
                          />
                        )}

                        <span>{menuItem.title}</span>
                      </div>
                    </NavLink>
                  )}
                </NavItem>
              );
            })}
          </>
        </NavItems>
      </Nav>
    </Navbar>
  );
};

const states = ({ userStore, currentStore, teamStore }) => {
  const { data: user } = userStore;
  const data = currentStore.data;
  const { data: teams } = teamStore.all;
  return {
    user,
    data,
    teams,
  };
};

export default connect(states)(Component);
