import { createEditorStateWithText } from "@draft-js-plugins/editor";
import React, { useEffect, useRef, useState } from "react";
import createToolbarPlugin from "@draft-js-plugins/static-toolbar";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import createVideoPlugin from "@draft-js-plugins/video";
import createImagePlugin from "@draft-js-plugins/image";
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
  CodeBlockButton,
  DraftJsButtonTheme,
} from "@draft-js-plugins/buttons";
import { useScreenshot } from "use-react-screenshot";
import "@draft-js-plugins/static-toolbar/lib/plugin.css";
import "draft-js/dist/Draft.css";
import { ToolBarMain, ToolBarWrapper } from "./components/Editor/style";
import Editor from "@draft-js-plugins/editor";
import "./style.css";
import { Input } from "./style";
import { FontSizes } from "../../../../constants/fontSizeCdos";
import SelectFontSize from "./components/SelectFontSize";
import { convertFromRaw, convertToRaw, EditorState, RichUtils } from "draft-js";
import SelectFontFamily from "./components/SelectFontFamily";
import { fontsFamilies } from "../../../../constants/fontSizeCdocs";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  deleteCDOCs,
  getCDOCs,
  newCDOCs,
  resetDeleteCDOCs,
  resetGetCdocs,
  resetNewCDOCs,
  resetUpdateCDOCs,
  updateCDOCs,
} from "../../../../store/actions/cdoc";
import { CDocDto } from "../../../../types/cdoc.dto";
import { FlexContainer } from "../../../../components/StyledComponents";
import { FiUpload } from "react-icons/fi";
import { AiFillLock, AiFillUnlock } from "react-icons/ai";
import { HiUserAdd } from "react-icons/hi";
import { FaSave, FaShare } from "react-icons/fa";
import { BiLink } from "react-icons/bi";
import { BsFillTrash2Fill } from "react-icons/bs";
import { CurrentDto } from "../../../../types/current.dto";
import SideBar from "./components/SideBar";
import { hideModal, showModal } from "../../../../store/actions/modal";
import PublishCdocModal from "./components/PublishCdocModal";
import Loader from "../../../../components/Loader";
import { StateDto } from "../../../../types/states.dto";
import { toast } from "react-toastify";
import { UsersDto } from "../../../../types/users.dto";
import AddUsersModal from "./components/AddUsersModal";
import ModalConfirmDelete from "../../../../components/ModalConfirmDelete";
import PrivateModal from "./components/PrivateModal";
import ShareModal from "./components/ShareModal";
import buttonStyles from "./buttonStyles.module.css";
import { SET_GET_CDOC } from "../../../../store/types/cdoc";
import { setFormData } from "../../../../helpers/formData";
import { CohortDto } from "../../../../types/cohort.dto";
import {
  getAllCohorts,
  resetGetAllCohortsData,
} from "../../../../store/actions/cohort";
import { getAllUnits, resetGetAllUnits } from "../../../../store/actions/unit";
import { UnitDto } from "../../../../types/unit.dto";
import { ErrorMessage, HelperLine } from "../../../../components/Form/Field";

export const stylesMapGlobal = [...FontSizes, ...fontsFamilies].reduce(
  (a, v) => ({ ...a, [v.label]: { [v.prop]: v.value } }),
  {}
);

const Component = ({
  cdoc,
  current,
  updateStatesCdoc,
  users,
  deleteCdocStates,
  getCdocStates,
  newCdoc,
  newCdocStates,
  cohorts,
  units,
}: {
  cdoc: CDocDto;
  current: CurrentDto;
  updateStatesCdoc: StateDto;
  users: UsersDto[];
  deleteCdocStates: StateDto;
  getCdocStates: StateDto;
  newCdoc: CDocDto;
  newCdocStates: StateDto;
  cohorts: CohortDto[];
  units: UnitDto[];
}) => {
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const [editorState, setEditorState] = useState<EditorState>(
    createEditorStateWithText("")
  );
  const [previewState, setPreviewState] = useState<EditorState>(
    createEditorStateWithText("")
  );
  const [cdocName, setCdocName] = useState("");
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [image, takeScreenshot] = useScreenshot();
  let editorRef = React.useRef<Editor>(null);
  let imagePreviewRef = React.useRef(null);
  const stylesMap = [...FontSizes, ...fontsFamilies].reduce(
    (a, v) => ({ ...a, [v.label]: { [v.prop]: v.value } }),
    {}
  );
  const getImage = () => takeScreenshot(imagePreviewRef.current);
  const textEditor = convertToRaw(editorState.getCurrentContent())
    .blocks.map((block) => (!block.text.trim() && "\n") || block.text)
    .join("\n");
  useEffect(() => {
    RichUtils.toggleInlineStyle(editorState, "FONT_FAMILY_Lato");
    RichUtils.toggleInlineStyle(editorState, "FONT_SIZE_10");
  }, []);

  useEffect(() => {
    !cohorts &&
      fetchOn &&
      dispatch(
        getAllCohorts({ filterBy: { academy: current.id, active: true } })
      );
  }, [cohorts, fetchOn]);

  useEffect(() => {
    if (cohorts && fetchOn) {
      dispatch(
        getAllUnits({
          filterBy: { cohort: { $in: cohorts.map((cohort) => cohort._id) } },
        })
      );
    }
  }, [cohorts]);

  useEffect(() => {
    if (!cohorts && !units) {
      setFetchOn(true);
    }
  }, [cohorts, units]);

  useEffect(() => {
    if (!!params.id) {
      dispatch(getCDOCs({ _id: params.id }));
    }
    if (!params.id && !!cdoc) {
      dispatch({ type: SET_GET_CDOC, response: null });
    }
  }, [params]);
  useEffect(() => {
    if (!!cdoc) {
      setCdocName(cdoc.name);
      setEditorState(
        EditorState.createWithContent(convertFromRaw({ ...cdoc.text }))
      );
    } else {
      setCdocName("");
      setEditorState(EditorState.createEmpty());
    }
  }, [cdoc]);

  useEffect(() => {
    if (updateStatesCdoc.success) {
      dispatch(getCDOCs({ _id: cdoc._id }));
      toast.success(`👌🏼 Cdoc guardado`);
      dispatch(hideModal());
      dispatch(resetUpdateCDOCs());
    }
    if (updateStatesCdoc.error) {
      dispatch(getCDOCs({ _id: cdoc._id }));
      toast.error(`${updateStatesCdoc.error || "No se pudo actualizar cdoc"}`);
      dispatch(resetUpdateCDOCs());
    }
  }, [updateStatesCdoc]);

  useEffect(() => {
    if (newCdocStates.success) {
      toast.success("Se creo el Cdoc exitosamente");
      dispatch(hideModal());
      if (!!newCdoc) {
        navigate(newCdoc._id);
      }
      setTimeout(() => {
        dispatch(resetNewCDOCs());
      }, 1000);
    }
    if (newCdocStates.error) {
      toast.error(newCdocStates.error);
      setTimeout(() => {
        dispatch(resetNewCDOCs());
      }, 1000);
    }
  }, [newCdocStates]);

  useEffect(() => {
    if (getCdocStates.success) {
      dispatch(resetGetCdocs());
    }
    if (getCdocStates.error) {
      toast.error(getCdocStates.error);
      dispatch(resetGetCdocs());
    }
  }, [getCdocStates]);

  const [{ plugins, Toolbar }] = useState(() => {
    const toolbarPlugin = createToolbarPlugin({
      theme: { buttonStyles, toolbarStyles: {} },
    });
    const linkfyPlugin = createLinkifyPlugin();
    const videoPlugin = createVideoPlugin();
    const dragdropPlugin = createImagePlugin();
    const { Toolbar } = toolbarPlugin;
    const plugins = [toolbarPlugin, linkfyPlugin, videoPlugin, dragdropPlugin];
    return {
      plugins,
      Toolbar,
    };
  });

  const onChangeEditorState = (newStateEditor) => {
    setEditorState((state) => {
      setPreviewState(state);
      return newStateEditor;
    });
  };

  const thereAreEditorChanges = () =>
    cdoc?.name !== cdocName ||
    convertToRaw(
      EditorState.createWithContent(
        convertFromRaw(cdoc.text)
      ).getCurrentContent()
    )
      .blocks.map((block) => (!block.text.trim() && "\n") || block.text)
      .join("\n") !== textEditor;

  const menu = [
    {
      cdocRole: ["OWNER"],
      Icon: FiUpload,
      toolTipName: "Publicar CDoc",
      onClick: () => {
        dispatch(showModal("publish-cdoc-modal"));
      },
    },
    {
      Icon: AiFillLock,
      cdocRole: ["OWNER"],
      IconActive: AiFillUnlock,
      toolTipName: "Cambiar visibilidad",
      onAvailable: () => !!cdoc,
      activeFunction: () => !!cdoc?.published,
      onClick: () => {
        dispatch(showModal("private-cdoc"));
      },
      onClickActive: () => {
        dispatch(showModal("private-cdoc"));
      },
    },
    {
      Icon: HiUserAdd,
      cdocRole: ["OWNER", "COOWNER"],
      toolTipName: "Añadir Usuarios",
      onClick: () => {
        dispatch(showModal("add-user-cdoc"));
      },
    },
    {
      Icon: FaSave,
      toolTipName: "Guardar",
      cdocRole: ["OWNER", "COOWNER"],
      onClick: () => {},
      onAvailable: () =>
        (!!cdoc && thereAreEditorChanges()) || (!cdoc && cdocName.length > 4),
      activeFunction: () =>
        (!!cdoc && thereAreEditorChanges()) || (!cdoc && cdocName.length > 4),
      onClickActive: () => {
        getImage()
          .then((res) => {
            if (!cdoc) {
              dispatch(
                newCDOCs(
                  setFormData(
                    {
                      picture: res,
                      text: JSON.stringify(
                        convertToRaw(editorState.getCurrentContent())
                      ),
                      name: cdocName,
                      type: "CDOC",
                      academy: current.id,
                    },
                    []
                  )
                )
              );
            } else {
              dispatch(
                updateCDOCs({
                  _id: cdoc?._id,
                  body: setFormData(
                    {
                      text: JSON.stringify(
                        convertToRaw(editorState.getCurrentContent())
                      ),
                      picture: res,
                      name: cdocName,
                      __v: cdoc.__v,
                    },
                    []
                  ),
                })
              );
            }
          })
          .catch((err) => toast.error("No se pudo guardar"));
      },
    },
    {
      Icon: BiLink,
      cdocRole: ["OWNER", "COOWNER", "READER"],
      toolTipName: "Copiar Link",
      onAvailable: () => !!cdoc?.available && !!cdoc?.published,
      onClick: () => {
        navigator.clipboard.writeText(
          `https://academia.batan.coop/dashboard/cdocs/${cdoc._id}`
        );
        toast.success(`Link copiado`);
      },
    },
    {
      Icon: FaShare,
      toolTipName: "Compartir",
      cdocRole: ["OWNER", "COOWNER", "READER"],
      onAvailable: () => !!cdoc?.available && !!cdoc?.published,
      onClick: () => {
        dispatch(showModal("share-user-cdoc"));
      },
    },
    {
      Icon: BsFillTrash2Fill,
      cdocRole: ["OWNER"],
      toolTipName: "Eliminar Cdoc",
      onAvailable: () => !!cdoc,
      onClick: () => {
        dispatch(showModal("confirm-delete-item-secuencial-modal"));
      },
    },
  ];
  useEffect(() => {
    return () => {
      dispatch(resetGetAllCohortsData());
      dispatch(resetGetAllUnits());
    };
  }, []);

  if ((!!params.id && !cdoc) || getCdocStates.loading)
    return <Loader color="Primary"></Loader>;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          flexFlow: "column-reverse",
          position: "relative",
        }}
      >
        <FlexContainer
          style={{ width: "100%" }}
          gap="5px"
          justify="space-between"
        >
          <div style={{ width: "calc(100% - 60px)" }} ref={imagePreviewRef}>
            <Editor
              editorState={editorState}
              onChange={(newEditorState) => {
                /*   if (!!imagePreviewRef.current) getImage(); */
                onChangeEditorState(newEditorState);
              }}
              preserveSelectionOnBlur
              placeholder="Haz click aqui para empezar a escribir!!"
              onBlur={(ev) => {
                editorRef.current?.focus();
                const isCollapsed = editorState.getSelection().isCollapsed();
                const isFocus = editorState.getSelection().getHasFocus();
                if (!isCollapsed && isFocus) {
                  setEditorState(
                    EditorState.forceSelection(
                      editorState,
                      editorState.getSelection()
                    )
                  );
                }
              }}
              plugins={plugins}
              customStyleMap={stylesMap}
              ref={editorRef}
            />
          </div>
          <SideBar cdoc={cdoc} menu={menu}></SideBar>
        </FlexContainer>

        {!!editorRef && (
          <ToolBarMain>
            <Toolbar>
              {(externalProps) => (
                <ToolBarWrapper>
                  <div
                    className="buttons"
                    style={{
                      height: "min-content",
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: "5px",
                    }}
                  >
                    <FlexContainer direction="column" gap="3px">
                      <div
                        style={{
                          width: "150px",
                          position: "relative",
                        }}
                      >
                        <Input
                          onChange={(ev) => {
                            setCdocName(ev.target.value);
                          }}
                          style={{
                            ...(cdocName.length < 5 && {
                              border: "1px solid red",
                            }),
                          }}
                          value={cdocName}
                        />
                      </div>
                      {cdocName.length < 5 && (
                        <ErrorMessage style={{ padding: "0px" }}>
                          {"Mas de 4 caracteres"}
                        </ErrorMessage>
                      )}
                    </FlexContainer>
                    <ItalicButton {...externalProps}></ItalicButton>
                    <BoldButton {...externalProps}></BoldButton>
                    <UnderlineButton {...externalProps}></UnderlineButton>
                    <CodeButton {...externalProps}></CodeButton>
                    <UnorderedListButton
                      {...externalProps}
                    ></UnorderedListButton>
                    <OrderedListButton {...externalProps}></OrderedListButton>
                    <BlockquoteButton {...externalProps}></BlockquoteButton>
                    <CodeBlockButton {...externalProps}></CodeBlockButton>
                    <div style={{ width: "60px" }}>
                      <SelectFontSize
                        previewState={previewState}
                        editorState={editorState}
                        setEditorState={onChangeEditorState}
                      ></SelectFontSize>
                    </div>
                    <div style={{ width: "60px" }}>
                      <SelectFontFamily
                        previewState={previewState}
                        editorState={editorState}
                        setEditorState={onChangeEditorState}
                      ></SelectFontFamily>
                    </div>
                  </div>
                </ToolBarWrapper>
              )}
            </Toolbar>
          </ToolBarMain>
        )}
      </div>

      <PublishCdocModal
        text={JSON.stringify(convertToRaw(editorState.getCurrentContent()))}
        name={cdocName}
        cdoc={cdoc}
        imagePreviewRef={imagePreviewRef}
      ></PublishCdocModal>

      {!!cdoc && <AddUsersModal cdoc={cdoc}></AddUsersModal>}
      {!!cdoc && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Eliminar",
            style: {
              style: { width: "170px", height: "40px" },
              color: "Danger",
              options: {
                type: "filled",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
              },
            },
          }}
          sucessAction={() => navigate("/dashboard/contents/cdocs")}
          states={deleteCdocStates}
          description={`El CDoc se eliminara para siempre del sistema.`}
          title={
            <span
              style={{ color: "#697482", fontSize: "20px", fontWeight: "600" }}
            >
              {`¿Seguro que quiere eliminar a ${cdoc.name}?`}
            </span>
          }
          elementActions={deleteCDOCs({ _id: cdoc._id })}
          resetAction={resetDeleteCDOCs}
          resetState={() => {}}
        ></ModalConfirmDelete>
      )}
      {!!cdoc && (
        <PrivateModal
          cdoc={{
            ...cdoc,
            available: !cdoc.available,
            text: convertToRaw(editorState.getCurrentContent()),
          }}
        ></PrivateModal>
      )}
      {!!cdoc && <ShareModal cdoc={cdoc}></ShareModal>}
    </>
  );
};

const states = ({
  cdocStore,
  currentStore,
  usersStore,
  cohortStore,
  unitStore,
}) => {
  const { data: cdoc, states: getCdocStates } = cdocStore.selected;
  const { states: updateStatesCdoc } = cdocStore.update;
  const { data: current } = currentStore;
  const { data: users } = usersStore.all;
  const { states: deleteCdocStates } = cdocStore.delete;
  const { states: newCdocStates, data: newCdoc } = cdocStore.new;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: units } = unitStore.allUnits;
  return {
    cdoc,
    current,
    updateStatesCdoc,
    users,
    deleteCdocStates,
    getCdocStates,
    newCdoc,
    newCdocStates,
    cohorts,
    units,
  };
};

export default connect(states)(Component);
